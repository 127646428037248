import * as React from 'react';
import * as ReactDOM from 'react-dom';
declare const axios
declare const $

// Require Editor CSS files.

import FroalaEditorComponent from 'react-froala-wysiwyg';

// // Import all Froala Editor plugins;
// import 'froala-editor/js/plugins.pkgd.min.js';

// Import a single Froala Editor plugin.
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/code_beautifier.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/entities.min.js";
import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/inline_class.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/print.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/word_paste.min.js";

// Import a third-party plugin.
// import 'froala-editor/js/third_party/image_tui.min.js';
// import 'froala-editor/js/third_party/embedly.min.js';
// import 'froala-editor/js/third_party/spell_checker.min.js';

// Include font-awesome css if required.
import 'froala-editor/js/third_party/font_awesome.min.js';

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

type Props = {
  inputId: string
  inputName: string
  initialValue: null | string
  labelText: string,
  required?: boolean
  errors?: object|string
  disabled?: boolean
  simpleToolbar?: boolean
  onTextChange?(text: string): void
}

type State = {
  required: boolean,
  model: string,
  config: object
}

class Wysiwyg extends React.Component<Props, State> {
  private editorRef = React.createRef<any>()

  constructor(props: Props) {
    super(props)
    const reactEl = this

    this.handleModelChange = this.handleModelChange.bind(this);

    this.state = {
      required: this.props.required || false,
      model: '',
      config: {
        key: window['frk'] ?? null,
        heightMin: 275,
        heightMax: 600,
        htmlExecuteScripts: false,
        imageUpload: false,
        videoUpload: false,
        pasteAllowLocalImages: true,
        events: {
          'initialized': function () {
            this.html.cleanEmptyTags();
          }
        },
        toolbarButtons: this.props.simpleToolbar ? this.basicToolbar() : this.fullToolbar(),
        quickInsertButtons: this.props.simpleToolbar
          ? ['table', 'ul', 'ol', 'hr']
          : ['video', 'fontawesome', 'table', 'ul', 'ol', 'hr'],
        tableCellMultipleStyles: false,
        tableCellStyles: {
          // 'fr-highlighted': 'Highlighted',
          // 'fr-thick': 'Thick',
          'hg-cell-purple': 'HG Purple',
          'hg-cell-orange': 'HG Orange',
        },
        tableStyles: {
          'hg-dashed-borders': 'Dashed Borders',
          'hg-alternate-rows': 'Alternate Rows',
          'hg-table-purple': 'HG Purple',
          'hg-table-orange': 'HG Orange',
        },
        inlineClasses: {
          'page-heading': 'HG Page Heading',
          'section-heading': 'HG Section Heading'
        },
        imageManagerLoadURL: '/admin/media_files/wysiwyg_list',
        listAdvancedTypes: true
      }
    }
  }

  fullToolbar () {
    return {
      'moreText': {
        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      },
      'moreRich': {
        'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'insertHR']
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help'],
        'align': 'right',
        'buttonsVisible': 2
      },
    }
  }

  basicToolbar () {
    return {
      'moreText': {
        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'clearFormatting']
      },
      'moreParagraph': {
        'buttons': ['formatOL', 'formatUL', 'paragraphFormat', 'outdent', 'indent', 'quote']
      },
      'moreRich': {
        'buttons': ['insertLink', 'insertTable', 'insertHR']
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'help'],
        'align': 'right',
        'buttonsVisible': 2
      },
    }
  }

  handleModelChange (value) {
    this.setState({
      model: value
    });

    if (this.props.onTextChange) {
      this.props.onTextChange(value)
    }
  }

  isEmpty(obj) {
    if (! obj) {
      return true
    }

    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

  componentDidMount () {
    this.setState({
      model: this.props.initialValue && this.props.initialValue !== 'null'
        ? this.props.initialValue
        : ''
    })
  }

  render() {
    return (
      <div className={`form-group mb-5 ${! this.isEmpty(this.props.errors) ? 'has-error' : ''}`} >
        <label className={`${this.props.required ? 'required' : ''}`} htmlFor={this.props.inputId}>{this.props.labelText}</label>
        <FroalaEditorComponent ref={this.editorRef} config={this.state.config} model={this.state.model} onModelChange={this.handleModelChange} tag='textarea'/>

        <input type="hidden" value={this.state.model} className={this.props.errors ? 'is-invalid' : ''} name={this.props.inputName} id={this.props.inputId} />
        { this.props.errors &&
          <div className="invalid-feedback">{this.props.errors}</div>
        }
      </div>

    )
  }
}

export default Wysiwyg