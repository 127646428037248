import * as React from 'react'
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Announcement, Message } from '@/types'

type Props = {
  announcements: Array<Message>
}

type State = {
  initialSettings: {}
}

class FeaturedAnnouncementSlider extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      initialSettings: {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  }

  render () {
    return (
      <Slider {...this.state.initialSettings}>
        {this.props.announcements.map((announcement, index) => {
          if (announcement.view_url && announcement.header_image_url) {
            return (
              <a href={announcement.view_url} key={index}>
                <img src={announcement.header_image_url} />
              </a>
            )
          }

          return null
        }, this)}
      </Slider>
    )
  }
}

export default FeaturedAnnouncementSlider