import * as React from 'react';

type Props = {
  titleButton?: string,
  secondTitleButton?: string,
  locationButton?: string,
  secondLocationButton?: string,
  title: string,
  subtitle?: string,
  buttonDisabled?: boolean
}

const ListItem: React.FunctionComponent<Props> = ({
  titleButton,
  secondTitleButton,
  locationButton,
  secondLocationButton,
  title,
  subtitle,
  buttonDisabled,
}: Props): JSX.Element => (
  <div className="header__top-bar">
    <div className="header__container">
      <i className="fal fa-book-open fa-3x header__icon" />
      <h1 className="header__title">{title}</h1>
      { subtitle && (
          <h3 className="header__subtitle">Description:{subtitle}</h3>
        )
      }
    </div>
    <div>
      {
        titleButton && !buttonDisabled && (
          <a href={locationButton} className="btn btn-primary">{titleButton}</a>
        )
      }
      {
        secondTitleButton && !buttonDisabled && (
          <a href={secondLocationButton} className="btn btn-primary ml-1">{secondTitleButton}</a>
        )
      }
    </div>
  </div>
);

export default ListItem;
