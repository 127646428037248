import * as React from 'react'
import { Tag } from '../types'


type Props = {
  tag: Tag
  onClick?: any //but is really a function
  canDelete?: boolean
}

class TagPill extends React.Component<Props> {
  render () {
    return (
      <div className={`tag badge badge-pill badge-primary tag-colour-${this.props.tag.colour}`} onClick={this.props.onClick}>
        { this.props.tag.name }
        { this.props.canDelete ? <span> &times;</span> : '' }
      </div>
    )
  }
}

export default TagPill