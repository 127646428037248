import * as React from 'react'
import Dropzone from 'react-dropzone'
declare const axios
declare const I18n
axios.defaults.timeout = 60000;
//Custom Types
import { MediaFile } from '../../types'

type State = {
  loading: boolean
  errors: any
  mediaFiles: MediaFile[]
}

class MediaFileUploader extends React.Component<{}, State> {
  private form = React.createRef<HTMLFormElement>()

  constructor (props: any) {
    super(props)

    this.state = {
      loading: false,
      mediaFiles: [],
      errors: {}
    }
  }

  deleteFile (index: number) {
    this.state.mediaFiles.splice(index, 1)

    this.setState({
      mediaFiles: [...this.state.mediaFiles],
    })
  }

  getFileName (index) {
    let file = this.state.mediaFiles[index]

    return file.id
      ? JSON.parse(this.state.mediaFiles[index].file_data).metadata.filename
      : this.state.mediaFiles[index].name
  }

  handleFileUploads (files) {
    let newFilePayload = this.state.mediaFiles
    files.forEach(file => {
      newFilePayload.push(file)
    })

    this.setState({
      mediaFiles: newFilePayload.concat([])
    })
  }

  handleSubmit (event) {
    event.preventDefault()
    this.setState({
      loading: true,
      errors: {}
    })

    let form = this.form.current
    let formData = new FormData(form)

    if (this.state.mediaFiles.length > 0) {
      this.state.mediaFiles.forEach(file => {
        if (file instanceof File) {
          formData.append("media_file[files][]", file)
        }
      })
    } else {
      formData.append("media_file[files]", null)
    }

    axios({
      method: 'POST',
      url: '/admin/media_files',
      data: formData
    })
      .then(function (response) {
        if (response.data.redirect) {
          window.location = response.data.redirect
        }
      }).catch((error) => {
        this.setState({
          loading: false,
          errors: error.response.data
        })
        window.scrollTo(0, 0)
      })
  }

  render() {
    return (
      <form ref={this.form}>
        <div className="row">

          <div className="col-md-12 drag-drop-container">
            <div className="form-group">
              <p>{I18n.t('views.common.attach_files')}</p>
              <div className="card">
                <div className="card-body">
                  <Dropzone accept=".png, .svg, .jpg, .jpeg, .gif, .pdf" onDrop={acceptedFiles => this.handleFileUploads(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p className="mb-0">
                            <span className="far fa-file mr-2"></span>
                            {I18n.t('views.forms.messaging.drag_drop_files.drag_drop')} <br />
                            {I18n.t('views.forms.messaging.drag_drop_files.or')} <br />
                            <span className="text-underline">{I18n.t('views.forms.messaging.drag_drop_files.click_here')}</span>
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <p>{I18n.t('views.common.files')}</p>
            {this.state.errors.length > 0 ? (
                <div className="alert no-top alert-danger">
                {
                  this.state.errors.map((error, index) => {
                    return <span className="d-block" key={index}>{error}</span>
                  })
                }
                </div>
              ) : (
                ''
              )
            }
            <table className="table">
              <thead>
                <tr>
                  <th>{I18n.t('views.common.file_name')}</th>
                  <th className="text-right">{I18n.t('views.common.options')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.mediaFiles.length > 0
                  ?
                  (
                    this.state.mediaFiles.map((mediaFile, index) => {
                      let fileName = this.getFileName(index)
                      return (
                        <tr key={fileName}>
                          <td>
                            {fileName}
                            <span className={`badge badge-success ml-2 ${mediaFile.hasOwnProperty('id') ? 'hidden' : ''}`}>{I18n.t('views.common.new')}</span>
                          </td>
                          <td className="text-right">
                            <span className="fal fa-trash text-danger" data-index={index} onClick={() => this.deleteFile(index)}></span>
                          </td>
                        </tr>
                      )
                    })
                  )
                  :
                  (
                    <tr>
                      <td colSpan={2} className="text-center">{I18n.t('views.common.no_files')}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>

          <div className="col-12 text-center mt-3">
            <button
              type="submit"
              name="commit"
              disabled={this.state.loading}
              className="btn btn-primary pull-right"
              onClick={this.handleSubmit.bind(this)}
              data-disable-with={I18n.t('views.common.uploading')}
            >
              {I18n.t('models.media_file.messaging.upload')}
              {
                this.state.loading &&
                <i className='far fa-spinner-third fa-spin btn-loader'></i>
              }
            </button>
          </div>

        </div>
      </form>
    )
  }
}

export default MediaFileUploader
