import axios from './axios';

export const createLesson = (id, body) =>
  axios.post(`/admin/courses/${id}/lessons`, { ...body })
    .then(({ data }) => data);

export const updateLesson = (courseId, lessonId, body) =>
  axios.put(`/admin/courses/${courseId}/lessons/${lessonId}`, { ...body })
    .then(({ data }) => data);

export const deleteLesson = (courseId, lessonId) =>
  axios.delete(`/admin/courses/${courseId}/lessons/${lessonId}`)
    .then(({ data }) => data);

export const submitLesson = (courseId, lessonId, body) =>
  axios.post(`/courses/${courseId}/lessons/${lessonId}/submit`, { ...body })
    .then(({ data }) => data);

export const updateSubmitLesson = (courseId, lessonId, body) =>
  axios.put(`/courses/${courseId}/lessons/${lessonId}/update_submit`, { ...body })
    .then(({ data }) => data);
