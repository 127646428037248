import {
  createCourse,
  updateCourse,
  deleteCourse,
} from './course';

import {
  createLesson,
  updateLesson,
  deleteLesson,
  submitLesson,
  updateSubmitLesson,
} from './lesson';

import {
  createResource,
  updateResource,
  deleteResource,
  deleteResourceCategory,
} from './resource';

export default {
  createCourse,
  updateCourse,
  deleteCourse,
  createLesson,
  updateLesson,
  deleteLesson,
  submitLesson,
  updateSubmitLesson,
  createResource,
  updateResource,
  deleteResource,
  deleteResourceCategory
};
