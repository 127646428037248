import * as React from 'react';
declare const I18n;

type Props = {
  value: string,
  error: string,
  type: string,
  field: string,
  label: string,
  className?: string,
  columnSize: number,
  style?: object,
  onChange(value: string): void,
}

const TextInput: React.FunctionComponent<Props> = ({
  value,
  error,
  type,
  field,
  label,
  className,
  columnSize,
  style,
  onChange,
}: Props): JSX.Element => (
  <div className={`col-md-${columnSize}`} style={style}>
    <div className="form-group">
      <label className={className} htmlFor={`${type}_${field}`}>{label}</label>
      <input
        className={`form-control ${error ? 'is-invalid' : ''}`}
        name={field.includes('_') ? `${type}[${field.replace('_', '][')}]` : `${type}[${field}]`}
        id={`${type}_${field}_id`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {error && (
          <div className="invalid-feedback d-block">
            {error}
          </div>
        )}
    </div>
  </div>
);

export default TextInput;
