import * as React from 'react';

import { BreadCrumb as BC } from '../../types.ts';

type Props = {
  locations: BC[],
}

const BreadCrumb: React.FunctionComponent<Props> = ({ locations }: Props): JSX.Element => (
  <div className="breadcrumbs">
    {
      locations.map((location, index) => (
        <div className="breadcrumbs__container" key={index}>
          <div
            onClick={() => location.url && (window.location.pathname = location.url)}
            className={"breadcrumbs__text app-text " +  ( location.url ? 'breadcrumbs__text--link' : '')}
          >
            {location.title}
          </div>
          {
            location.url && locations[index + 1] && (
              <i className="fal fa-angle-right fa-2x"></i>
            )
          }
        </div>
      ))
    }
  </div>
);

export default BreadCrumb;
