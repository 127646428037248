import * as React from 'react'
declare const I18n

type Props = {
  id?: string
  disabled?: boolean
  errors?: any
  accepts?: string
  existingFileName?: string
  existingFileUrl?: string
  required?: boolean
  parent: string
  field: string
  label: string
  onChange?(value: string): void,
  onDelete?(): void,
}

type State = {
  required: boolean
  file: any
  existingMarkedForRemoval: boolean
}

class SingleFileUpload extends React.Component<Props, State> {
  private fileInput = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      required: this.props.required,
      file: {},
      existingMarkedForRemoval: false
    }
  }

  setHeaderImageName(fileData) {
    this.setState({
      file: {
        name: fileData[0] ? fileData[0].name : null,
        existingFileUrl: null
      }
    })
  }

  resetFileValue() {
    this.fileInput.current.value = null
    this.fileInput.current.files = null
    this.setHeaderImageName('')
    this.setState({ existingMarkedForRemoval: true })

    if (this.props.onDelete) {
      this.props.onDelete()
    }
  }

  componentDidMount() {
    if (this.props.existingFileName && this.props.existingFileUrl) {
      this.setState({
        file: {
          name: this.props.existingFileName,
          url: this.props.existingFileUrl
        }
      })
    }
  }

  fileInputChanged(event) {
    this.setHeaderImageName(event.target.files)

    if (this.props.onChange) {
      this.props.onChange(event.target.files)
    }
  }

  fileNameContainer() {
    return (
      <div className="file-name-container">
        <span>
          {
            this.state.file.url
              ? <a target="_blank" href={this.state.file.url}>{this.state.file.name}</a>
              : this.state.file.name
          }
          {
            this.props.required
              ? <i onClick={() => this.fileInput.current.click()} className="float-right fal fa-retweet cursor-pointer mt-1"></i>
              : <i onClick={() => this.resetFileValue()} className="float-right fal fa-trash cursor-pointer"></i>
          }
        </span>
      </div>
    )
  }

  emptyFileContainer() {
    return (
      <div className="no-file-container">
        <span>
          <i className="fal fa-file mr-2 cursor-pointer"></i>
          <u onClick={() => this.fileInput.current.click()} className='cursor-pointer'>{ I18n.t('views.forms.messaging.drag_drop_files.click_here') }</u>
        </span>
        {
          this.state.existingMarkedForRemoval
          &&
          <input type="hidden" name={this.props.parent + `[remove_${this.props.field}]`} value='1' />
        }
      </div>
    )
  }

  fieldName () {
    return `${this.props.parent}[${this.props.field}]`
  }

  hasErrors() {
    return this.props.errors && this.props.errors.length > 0
  }

  hasFile () {
    return Object.keys(this.state.file).length && this.state.file.name
  }

  render() {
    return (
      <div className="form-group custom-file-container">
        <label className={`${this.props.required ? 'required' : ''} ${this.hasErrors() ? 'text-danger' : ''}`} htmlFor={this.fieldName()}>{this.props.label}</label>
          <input
            type="file"
            accept={this.props.accepts}
            disabled={this.props.disabled}
            name={this.fieldName()}
            id={this.props.id}
            ref={this.fileInput}
            onChange={(event) => this.fileInputChanged(event)}
            className="hidden"
          />

          {
            this.hasFile()
              ? this.fileNameContainer()
              : this.emptyFileContainer()
          }

          {
            this.hasErrors()
            &&
            <div className="d-block invalid-feedback">{this.props.errors[0]}</div>
          }
      </div>
    )
  }
}

export default SingleFileUpload