import * as React from 'react'
import { UberTable, RowDefinition, CtaDefinition } from './UberTable'
import { Message } from '../../types'
declare const I18n

type Props = {
  cta?: CtaDefinition
}

class MessagesTable extends React.Component<Props> {
  private rowDefinition: RowDefinition<Message>[]

  constructor(props: Props) {
    super(props)

    this.rowDefinition = [
      {
        title: I18n.t('models.common.title'),
        attribute_key: 'title',
        sort_key: 'title',
        render: (message: Message) => {
          return message.read
            ? <a href={message.view_url}>{message.title}</a>
            : <strong><a href={message.view_url}>{message.title}</a></strong>
        }
      },
      {
        title: I18n.t('models.announcement.attributes.featured'),
        attribute_key: 'is_featured',
        textAlignment: 'center',
        render: (message: Message) => {
          return message.is_featured ? I18n.t('views.common.yes') : I18n.t('views.common.no')
        }
      },
      {
        title: I18n.t('models.message.attributes.received_date'),
        attribute_key: 'created_at',
        textAlignment: 'center',
        render: (message: Message) => {
          return message.created_at_javascript
        }
      }
    ]
  }

  render() {
    return <UberTable<Message>
      rowDefinition={this.rowDefinition}
      searchType='message'
      {...this.props}
    />
  }
}

export default MessagesTable
