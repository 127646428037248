declare const I18n

import * as React from 'react'
import { UberTable, RowDefinition } from './UberTable'
import { Learner } from '../../types'

type Props<T> = {}

class LearnersTable extends React.Component<Props<Learner>> {
  private rowDefinition: RowDefinition<Learner>[]

  constructor(props: Props<Learner>) {
    super(props)

    this.rowDefinition = [
      {
        title: I18n.t('models.common.name'),
        attribute_key: 'name',
        sort_key: 'name',
        render: (learner: Learner) => {
          return !learner.is_deleted
            ? <a href={learner.edit_url}>{learner.name}</a>
            : learner.name
        }
      },
      {
        title: I18n.t('models.user.attributes.username'),
        attribute_key: 'username',
        sort_key: 'username'
      },
      {
        title: I18n.t('models.common.status'),
        attribute_key: 'active',
        sort_key: 'active',
        render: (learner: Learner) => {
          return learner.is_active
            ? <span className="badge badge-wide badge-success">
                <i className="fas fa-check"></i>
              </span>
            : <span className="badge badge-wide badge-warning">
                <i className="fas fa-clock"></i>
              </span>
        }
      },
      {
        title: I18n.t('models.resource.attributes.level'),
        attribute_key: 'level',
        sort_key: 'level',
      },
      {
        title: I18n.t('models.learner.attributes.current_organization'),
        attribute_key: 'organization_name',
        render: (learner: Learner) => {
          return learner.organization_name
            ? learner.organization_name
            : 'N/A'
        }
      }
    ]
  }

  render() {
    return <UberTable<Learner>
      rowDefinition={this.rowDefinition}
      searchType='learner'
      {...this.props}
    />
  }
}

export default LearnersTable
