import * as React from 'react'
import Dropzone from 'react-dropzone'

import SingleFileUpload from './SingleFileUpload'
import Wyswiyg from '../inputs/Wysiwyg'
declare const axios
declare const I18n
declare const $
axios.defaults.timeout = 60000;

//Custom Types
import { User, Announcement, AnnouncementFile, Region, Organization } from '../../types'

type Props = {
  endpoint_url: string
  user: User
  announcement: Announcement
  announcementFiles: AnnouncementFile[]
  regions: Region[]
  organizations: Organization[]
}

type State = {
  target: string
  loading: boolean
  errors: any,
  fileIdsToDelete: number[],
  select2: any
} & Props

class AnnouncementForm extends React.Component<Props, State> {
  private form = React.createRef<HTMLFormElement>()
  private regionDropdown = React.createRef<HTMLSelectElement>()
  private organizationDropdown = React.createRef<HTMLSelectElement>()

  constructor (props: Props) {
    super(props)

    let target = (this.props.user.role === 'facilitator' || this.props.announcement.organization_id) ? 'organization' : 'general'

    this.state = {
      ...this.props,
      loading: false,
      errors: {},
      target: target,
      fileIdsToDelete: [],
      select2: null
    }
  }

  setAnnouncementProperty (property, value) {
    let announcement = { ...this.state.announcement }
    announcement[property] = value

    this.setState({
      announcement
    })
  }

  setTargetAudience (targetAudience: string) {
    $(this.state.select2[0]).val('').trigger('change');

    if (targetAudience == 'all') {
      this.setState({
        target: targetAudience,
        announcement: {
          ...this.state.announcement,
          organization_id: null,
        }
      })
    } else if (targetAudience == 'organization') {
      this.setState({
        target: targetAudience,
        announcement: {
          ...this.state.announcement,
          region_id: null
        }
      })
    }
  }

  getRegion (regionId: number) {
    return this.state.regions.find(region => region.id == regionId)
  }

  deleteFile (index: number) {
    let delete_ids = []
    let file = this.state.announcementFiles[index]

    if (file.announcement_id) {
      delete_ids.push(file.id)
    }

    this.state.announcementFiles.splice(index, 1)

    this.setState({
      announcementFiles: [...this.state.announcementFiles],
      fileIdsToDelete: this.state.fileIdsToDelete.concat(delete_ids)
    })
  }

  getFileName(index) {
    let file = this.state.announcementFiles[index]

    return file.announcement_id
      ? JSON.parse(this.state.announcementFiles[index].file_data).metadata.filename
      : this.state.announcementFiles[index].name
  }

  toggleFeatured (isChecked: boolean) {
    let announcementObj = {
      ...this.state.announcement,
      is_featured: isChecked
    }

    if (isChecked) {
      announcementObj.organization_id = null
      announcementObj.region_id = null
      this.organizationDropdown.current.selectedIndex = 0
      this.regionDropdown.current.selectedIndex = 0
    }

    this.setState({ announcement: announcementObj })
  }

  handleFileUploads(files) {
    let newFilePayload = this.state.announcementFiles
    files.forEach(file => {
      newFilePayload.push(file)
    })

    this.setState({
      announcementFiles: newFilePayload.concat([])
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      loading: true,
      errors: {}
    })

    let form = this.form.current
    let formData = new FormData(form)


    if (this.state.announcementFiles.length > 0) {
      this.state.announcementFiles.forEach(file => {
        if (file instanceof File) {
          formData.append("announcement[new_files][]", file)
        }
      })
    }

    if (this.state.fileIdsToDelete.length > 0) {
      this.state.fileIdsToDelete.forEach((id) => {
        formData.append('announcement[deleted_file_ids][]', id.toString())
      })
    }

    axios({
      method: this.state.announcement.id ? 'PATCH' : 'POST',
      url: this.state.endpoint_url,
      data: formData
    }).then(function (response) {
      if (response.data.redirect) {
        window.location = response.data.redirect
      }
    }).catch(function (error) {
      this.setState({
        loading: false,
        errors: error.response.data
      })
      window.scrollTo(0, 0)
    }.bind(this))
  }

  formatDate(date) {
    if (!date) {
      return ''
    }

    let formattedDate = new Date(date + ' 00:00:00')
    let dd = formattedDate.getDate().toString().padStart(2, '0')
    let mm = (formattedDate.getMonth() + 1).toString().padStart(2, '0') //January is 0!
    let yyyy = formattedDate.getFullYear()

    return yyyy + '-' + mm + '-' + dd
  }

  componentDidMount () {
    let react = this

    $(document).ready(function() {
      var select2Dropdown = $('#announcement_organization_id').select2();
      react.setState({
        select2: select2Dropdown
      })

      $('.flatpickr-react').each(function() {
        $(this).flatpickr({
          dateFormat: "Y-m-d",
          defaultDate: this.value,
          onChange: function(selectedDates, dateStr, instance) {
            react.setAnnouncementProperty('expiration_date_javascript', dateStr)
          },
        });
      })
    })
  }

  render() {
    let date = this.formatDate(this.state.announcement.expiration_date_javascript || null)

    return (
      <form ref={this.form}>
        <div className="announcement-form-container">

          <h2 className="section-heading">{I18n.t('views.common.details')}</h2>

          <div className="row">

            { this.state.user.role !== 'facilitator' &&
              <div className="col-md-4">
                <div className="form-group">
                  <label className="required" htmlFor="announcement_is_featured">{I18n.t('views.common.type')}</label>
                  <select
                    className="form-control"
                    disabled={this.state.announcement.id != null}
                    id="announcement_is_featured"
                    name="announcement[is_featured]"
                    defaultValue={this.state.announcement.is_featured ? 'true' : 'false'}
                    onChange={(event) => this.setAnnouncementProperty('is_featured', event.target.value == 'true')}
                  >
                    <option value='false'>{I18n.t('models.announcement.attributes.standard')}</option>
                    <option value='true'>{I18n.t('models.announcement.attributes.featured')}</option>
                  </select>
                </div>
              </div>
            }

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="announcement_expiration_date">
                  {I18n.t('models.announcement.attributes.expiry')}
                </label>

                <input
                  className={`form-control flatpickr-react ${this.state.errors.expiration_date ? 'is-invalid' : ''}`}
                  type="date"
                  disabled={this.state.announcement.id != null}
                  name="announcement[expiration_date]"
                  id="announcement_expiration_date"
                  value={date}
                  onChange={() => {}}
                />
                { this.state.errors.expiration_date &&
                  <div className="invalid-feedback">{this.state.errors.expiration_date[0]}</div>
                }
              </div>
            </div>

            { this.state.user.role !== 'facilitator' && this.state.announcement.is_featured &&
              <div className='col-md-4'>
                <input type="hidden"
                  disabled={!this.state.announcement.is_featured || this.state.announcement.id != null}
                  name="announcement[header_image]"
                  value="true"
                />

                <SingleFileUpload
                  id="announcement_header_image"
                  parent="announcement"
                  field="header_image"
                  existingFileName={this.state.announcement.header_image_name}
                  existingFileUrl={this.state.announcement.header_image_url}
                  label={I18n.t('models.announcement.attributes.featured_image')}
                  accepts="image/jpg, image/jpeg, image/png, image/gif, image/svg+xml"
                  errors={this.state.errors.header_image ? this.state.errors.header_image : null}
                  required={true}
                />
              </div>
            }
          </div>

          <h2 className="section-heading">{I18n.t('models.announcement.attributes.targeting')}</h2>

          { this.state.user.role !== 'facilitator' &&
            <div className="row">
              <div className='col-md-4'>
                <div className="form-group">
                  <label className="required" htmlFor="announcement_language">{I18n.t('models.common.language')}</label>
                  <select
                    className="form-control"
                    disabled={this.state.announcement.id != null}
                    id="announcement_language"
                    name="announcement[language]"
                    defaultValue={this.state.announcement.language}
                    onChange={(event) => this.setAnnouncementProperty('language', event.target.value)}
                  >
                    <option value='en'>{I18n.t('views.common.english')}</option>
                    <option value='fr'>{I18n.t('views.common.french')}</option>
                  </select>
                </div>
              </div>


            <div className={`col-md-4 ${this.state.user.role === 'facilitator' ? 'hidden' : ''}`}>
              <div className="form-group">
                <label className="required" htmlFor="target">{I18n.t('models.announcement.attributes.target_audience')}</label>
                <select
                  className="form-control"
                  disabled={this.state.announcement.id != null}
                  id="target"
                  name="target"
                  defaultValue={this.state.target}
                  onChange={(event) => this.setTargetAudience(event.target.value)}
                >
                  <option value='all'>{I18n.t('models.announcement.attributes.general')}</option>
                  <option value='organization'>{I18n.t('models.announcement.attributes.organization_club')}</option>
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className={this.state.target == 'organization' ? 'required' : ''} htmlFor="announcement_organization_id">{I18n.t('models.organization.attributes.organization')}</label>
                <select
                  ref={this.organizationDropdown}
                  disabled={this.state.target !== 'organization' || this.state.announcement.id != null}
                  className="form-control select2"
                  id="announcement_organization_id"
                  name="announcement[organization_id]"
                  defaultValue={this.state.announcement.organization_id || ''}
                  onChange={(event) => {
                    this.setAnnouncementProperty('organization_id', event.target.value)
                  }}
                >
                  <option value=''>{I18n.t('models.announcement.attributes.select_organization')}</option>
                  {this.state.organizations.map((value) => {
                    let region = this.getRegion(value.region_id)
                    return <option key={value.id} value={value.id}>{`${value.name} ( ${value.city}, ${region.full_name})`}</option>
                  })}
                </select>
              </div>
            </div>

          </div>
        }

        { this.state.user.role === 'facilitator' &&
          <div>
            <input type="hidden" name="announcement[language]" value="all" />
            <input type="hidden" name="announcement[organization_id]" value={this.state.user.current_organization.id} />
            <input type="hidden" name="announcement[user_type]" value="all" />
          </div>
        }

          <div className="row">

            { this.state.user.role !== 'facilitator' &&
              <div className='col-md-4'>
                <div className="form-group">
                  <label htmlFor="announcement_user_type">{I18n.t('models.announcement.attributes.user_type')}</label>
                  <select
                    className="form-control"
                    disabled={this.state.announcement.id != null}
                    id="announcement_user_type"
                    name="announcement[user_type]"
                    defaultValue={this.state.announcement.user_type}
                    onChange={(event) => this.setAnnouncementProperty('user_type', event.target.value)}
                  >
                    <option value="all">{I18n.t('views.common.all')}</option>
                    <option value='facilitator'>{I18n.t('models.facilitator.attributes.facilitator')}</option>
                    <option value='learner'>{I18n.t('models.learner.attributes.learners')}</option>
                  </select>
                </div>
              </div>
            }

            { this.state.user.role !== 'facilitator' &&
              <div className="col-md-4">
                <div className="form-group">
                  <label className="required" htmlFor="announcement_region_id">{I18n.t('models.announcement.attributes.region')}</label>
                  <select
                    ref={this.regionDropdown}
                    // disabled={this.state.target !== 'general'}
                    className="form-control"
                    id="announcement_region_id"
                    name="announcement[region_id]"
                    disabled={this.state.announcement.id != null || this.state.target == 'organization'}
                    defaultValue={this.state.announcement.region_id || ''}
                    onChange={(event) => this.setAnnouncementProperty('region_id', parseInt(event.target.value))}
                  >
                    <option value="">{I18n.t('views.common.all')}</option>
                    {this.state.regions.map((value) => {
                      return <option key={value.id} value={value.id}>{value.full_name}</option>
                    })}
                  </select>
                </div>
              </div>
            }
          </div>

          <h2 className="section-heading">{I18n.t('views.common.content')}</h2>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="required" htmlFor="announcement_title">
                  {I18n.t('models.common.title')}
                </label>

                <input
                  className={`form-control ${this.state.errors.title ? 'is-invalid' : ''}`}
                  type="text"
                  value={this.state.announcement.title || ''}
                  onChange={(event) => this.setState({ announcement: { ...this.state.announcement, title: event.target.value } })}
                  name="announcement[title]"
                  id="announcement_title"
                  disabled={this.state.announcement.id != null}
                />
                { this.state.errors.title &&
                  <div className="invalid-feedback">{this.state.errors.title[0]}</div>
                }
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <Wyswiyg
                  inputId="announcement_body"
                  inputName="announcement[body]"
                  initialValue={this.state.announcement.body}
                  required={true}
                  disabled={this.state.announcement.id != null}
                  labelText={I18n.t('models.announcement.attributes.body')}
                  errors={this.state.errors.body || null}
                ></Wyswiyg>
              </div>
            </div>

            { this.state.announcement.id == null &&
              <div className="col-md-12 drag-drop-container">
                <div className="form-group">
                  <label>{I18n.t('views.common.attach_files')}</label>
                  <div className="card">
                    <div className="card-body">
                      <Dropzone onDrop={acceptedFiles => this.handleFileUploads(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p className="mb-0">
                                <span className="far fa-file mr-2"></span>
                                {I18n.t('views.forms.messaging.drag_drop_files.drag_drop')} <br />
                                {I18n.t('views.forms.messaging.drag_drop_files.or')} <br />
                                <span className="text-underline">{I18n.t('views.forms.messaging.drag_drop_files.click_here')}</span>
                            </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="col-md-12">
              <label>{I18n.t('views.common.files')}</label>
              <table className="table">
                <thead>
                  <tr>
                    <th>{I18n.t('views.common.file_name')}</th>
                    <th className="text-right">{I18n.t('views.common.options')}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.announcementFiles.length > 0 ? (
                    this.state.announcementFiles.map((announcementFile, index) => {
                      let fileName = this.getFileName(index)
                      return (
                        <tr key={fileName}>
                          <td>
                            {fileName}
                            <span className={`badge badge-success ml-2 ${announcementFile.hasOwnProperty('announcement_id') ? 'hidden' : ''}`}>{I18n.t('views.common.new')}</span>
                          </td>
                          <td className="text-right">
                            { this.state.announcement.id == null &&
                              <span className="fal fa-trash text-danger" data-index={index} onClick={() => this.deleteFile(index)}></span>
                            }
                          </td>
                        </tr>
                      )
                    }, this)
                  ) : (
                      <tr>
                        <td colSpan={12} className="text-center">{I18n.t('views.common.no_files')}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            { this.state.announcement.id == null &&
              <div className="col-12 text-center mt-3">
                <button
                  type="submit"
                  name="commit"
                  disabled={ this.state.loading }
                  className="btn btn-primary pull-right"
                  onClick={this.handleSubmit.bind(this)}
                  data-disable-with={`${this.state.announcement.id ? I18n.t('update') : I18n.t('create')} ${I18n.t('models.announcement.attributes.announcement')}`}
                >
                  {`${this.state.announcement.id ? I18n.t('views.forms.save_changes') : I18n.t('views.forms.submit')}`}
                  {
                    this.state.loading &&
                    <i className='far fa-spinner-third fa-spin btn-loader'></i>
                  }
                </button>
              </div>
            }
          </div>
        </div>
      </form>
    )
  }
}

export default AnnouncementForm
