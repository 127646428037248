document.addEventListener("DOMContentLoaded", function () {
  setTimeout(function () {
    if (jQuery().dataTable) {
      let config = {
        dom: 'Blfrtip',
        scrollX: true,
        autoWidth: false,
        buttons: [
          { extend: 'excel', text: I18n.t('views.forms.messaging.export'), className: 'btn-primary' }
        ],
        rowCallback: function(row, data, index) {
          data.forEach(function(d, i) {
            if (d === 'Y') {
              $('td:eq('+i+')', row).addClass('yes')
            } else if (d === 'N') {
              $('td:eq('+i+')', row).addClass('no')
            }
          })
        }
      }

      if ($('#organization-report-table').length > 0) {
        config.fixedColumns = {
          leftColumns: 3
        }
      }

      $('table.data-table').DataTable(config)

      let exportButton = $('.dataTables_wrapper .dt-buttons')
      $('.dataTables_wrapper').append(exportButton)
    }
  })
})