declare const axios: any

import _ from 'lodash'

import * as React from 'react'
import ReactTags from 'react-tag-autocomplete'
import { Tag } from '../../types'
import TagPill from '../TagPill'

type Props = {
  taggable_type: string
  tags: Tag[]
  context?: string
  onChange?(value: any): void
}

type State = {
  tags: Tag[]
  suggestions: Tag[]
  value: string
  isLoading: boolean
  error: boolean
  context: string
  onChange?(value: any): void
}

class TypeAhead extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    let state = {
      tags: this.props.tags ? [].concat(this.props.tags) : [],
      suggestions: [],
      value: '',
      isLoading: false,
      error: false,
      context: this.props.context || 'tag',
      onChange: this.props.onChange ? this.props.onChange : null
    } as State

    this.state = state
  }

  async fetchSuggestions (query) {
    this.setState({ isLoading: true })

    let url = `/tags?query=${query}&taggable_type=${this.props.taggable_type}&context=${this.state.context}`

    try {
      let request = await axios.get(url)
      let response = request.data

      this.setState({
        isLoading: false,
        suggestions: response.tags
      })
    } catch (err) {
      this.setState({
        isLoading: true,
        error: true
      })
      console.log(`TagInput fetchSuggestions: `, err)
    }
  }

  onDelete(i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
    this.state.onChange(tags)
  }

  onAddition(tag) {
    const existingNames = this.state.tags.map(t => t.name.toLowerCase())
    if (existingNames.includes(tag.name.toLowerCase())) return

    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
    this.state.onChange(tags)
  }

  HackergalTag (props) {
    return <TagPill tag={props.tag} canDelete={true} onClick={props.onDelete} />
  }

  render() {
    return (
      <div>
        <ReactTags
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          onDelete={this.onDelete.bind(this)}
          onAddition={this.onAddition.bind(this)}
          onInput={_.debounce(this.fetchSuggestions.bind(this), 300)}
          allowNew={true}
          tagComponent={this.HackergalTag}
          placeholderText=''
          delimiters={['Enter', 'Tab', ',']}
          classNames={{
            root: 'tags-input',
            rootFocused: 'root-focused',
            selected: 'tags-list',
            selectedTag: 'badge badge-pill badge-primary',
            selectedTagName: 'react-tags__selected-tag-name',
            search: 'search-wrapper',
            searchWrapper: 'search',
            searchInput: 'new-tag-input',
            suggestions: 'tags-suggestions',
            suggestionActive: 'is-active',
            suggestionDisabled: 'is-disabled'
          }}
        />
        { this.state.tags &&
          this.state.tags.map((tag) => {
            return (
              <input type='hidden' name={`${this.props.taggable_type.toLowerCase()}[${this.state.context}_list][]`} value={tag.name} key={tag.name} />
            )
          })
        }
      </div>
    )
  }
}

export default TypeAhead