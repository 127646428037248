window.RAILS_ENV = process.env.RAILS_ENV;

// Expose webpack jQuery globally on the page...
window.$ = $;
window.jQuery = $;
import axios from '../api/axios';
window.axios = axios;

import '@hotwired/turbo-rails';

// turbo drive breaks react navigation
// can re-enable for links and such by explicitly setting data-turbo="true" on those elements
Turbo.session.drive = false;

import 'bootstrap';
import '../stylesheets/application';
require('flatpickr/dist/flatpickr');
require('flatpickr/dist/flatpickr.min.css');
require('froala-editor/css/froala_style.min.css');
require('froala-editor/css/froala_editor.pkgd.min.css');

import './i18n';

// Auto-show Bootstrap Tabs based on the anchor in the page.
import './onload_tab_handler';

// Add a spinner to the submit buttons on forms.
import './form_submit_spinner';

// Shared form functionality for things like "other" hide/show options
import './shared-form-functionality';

// Adds a watcher to warn user of form changes on reload.
import './form_warn_changes_on_reload'

// Adds a watcher to warn user of form changes on reload.
import './language_switcher'

// Imports the reporting export javascript code
import './admin-reporting-export'

// Support component names relative to this directory:
const ReactRailsUJS = require("react_ujs");
const componentRequireContext = require.context("../components", true);

ReactRailsUJS.useContext(componentRequireContext)

document.addEventListener("DOMContentLoaded", function() {
  $('#return-button').click(function(e) {
    history.back()
  })
});
