declare const I18n

import * as React from 'react'
import { ResourceCategory } from '../../types'

type Props = {
  category: ResourceCategory
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

class CategoryTile extends React.Component<Props, {}> {
  render () {
    return (
      <div className='resource-card resource-card-video cursor-pointer' onClick={this.props.onClick}>
        {
          this.props.category.cover_image_url
          &&
          <div className='resource-card-image' style={{ backgroundImage: `url('${this.props.category.cover_image_url}')` }}></div>
        }
        <div className='resource-card-content'>
          <div className='resource-card-title'>
            <div className="resource-card-title-content">
              <div className="resource-card-type">
                <i className="resource-card-type-icon far fa-archive"></i>
                <span>{I18n.t('models.resource.attributes.category')}</span>
              </div>
              {this.props.category.name}
            </div>
          </div>

          <div className="resource-card-meta">
            <div className="resource-card-meta-text"></div>
            <div className="resource-card-meta-text"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default CategoryTile