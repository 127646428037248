import * as React from 'react';
import _ from 'lodash'
declare const I18n;

type Props = {
  title: string,
  description: string,
  targetRole: string,
  index: number,
  id: number,
  image?: string,
  isFacilitator?: boolean,
  isLearner?: boolean,
  handleDelete(id: number): void,
}

const CardItem: React.FunctionComponent<Props> = ({
  title,
  description,
  targetRole,
  index,
  id,
  handleDelete,
  image,
  isFacilitator,
  isLearner,
}: Props): JSX.Element => {
  const mod = index % 3;
  let levelText = `${_.capitalize(targetRole)}`;

  const isAdmin = !(isLearner || isFacilitator);

  const imageNode = (
    <div className={`card-list-item__image-container`}>
      {
        image ? (
          <div className="card-list-item__image card-list-item__image--background" style={{ backgroundImage: `url(${image})` }} />
        ) : (
          <div className={`card-list-item__image card-list-item__image--default`} />
        )
      }
    </div>
  );

  return (
    <div
      className="card-list-item"
      style={{
        marginRight: (mod === 0 || mod === 1) ? '1.2%' : 0,
        marginLeft: (mod === 1 || mod === 2) ? '1.2%' : 0
      }}
    >
      <a
        style={{ cursor: 'pointer' }}
        href={`${isAdmin ? '/admin' : ''}/explore_categories/${id}/resources`}
      >
        {imageNode}
      </a>

      {
        levelText && (
          <div className="card-list-item__card-type-container">
            <i className="far fa-book-open fa-1x card-list-item__icon" />
            <div className="card-list-item__level">
              {levelText}
            </div>
          </div>
        )
      }

      <div className="card-list-item__meta-container">
        <div className="card-list-item__title-container">
          <div id={`resource_category_title_${id}`} className="card-list-item__title">{title}</div>
          {
            isAdmin && (
              <div className="card-list-item__admin-container">
                <a href={`/admin/explore_categories/${id}/edit`}><i className="fal fa-edit card-list-item__icon--admin" /></a>
                <div id={`delete_${id}`} onClick={() => handleDelete(id)}><i className="fal fa-trash card-list-item__icon--admin" /></div>
              </div>
            )
          }
        </div>
        <div className="card-list-item__desc-container">
          <div id={`resource_category_description_${id}`} className="card-list-item__description">{description}</div>
        </div>
      </div>
    </div>
  );
}

export default CardItem;
