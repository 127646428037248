import * as React from 'react'
import { UberTable, RowDefinition, CtaDefinition } from './UberTable'
import { Announcement } from '../../types'
declare const I18n

type Props = {
  cta?: CtaDefinition,
  for_learners?: boolean,
  includeDeleted?: boolean
}

class AnnouncementsTable extends React.Component<Props> {
  private rowDefinition: RowDefinition<Announcement>[]

  constructor(props: Props) {
    super(props)

    this.rowDefinition =
      this.props.for_learners ?
        [
          {
            title: I18n.t('models.announcement.attributes.post_date'),
            attribute_key: 'created_at',
            sort_key: 'created_at'
          },
          {
            title: I18n.t('models.common.title'),
            attribute_key: 'title',
            sort_key: 'title',
            render: (announcement: Announcement) => {
              return <a href={announcement.view_url}>{announcement.title}</a>
            }
          },
          {
            title: I18n.t('models.announcement.attributes.author'),
            attribute_key: 'author',
            sort_key: 'author'
          }
        ]
      : [
          {
            title: I18n.t('models.common.title'),
            attribute_key: 'title',
            sort_key: 'title',
            render: (announcement: Announcement) => {
              return <a href={announcement.view_url}>{announcement.title}</a>
            }
          },
          {
            title: I18n.t('models.announcement.attributes.featured'),
            attribute_key: 'is_featured',
            textAlignment: 'center',
            render: (announcement: Announcement) => {
              return announcement.is_featured ? I18n.t('views.common.yes') : I18n.t('views.common.no')
            }
          },
          {
            title: I18n.t('models.common.language'),
            attribute_key: 'language',
            textAlignment: 'center',
            render: (announcement: Announcement) => {
              return announcement.language == 'en' ? I18n.t('views.common.english') : I18n.t('views.common.french')
            }
          },
          {
            title: I18n.t('models.announcement.attributes.expiration_date'),
            attribute_key: 'expiration_date',
            textAlignment: 'center',
          }
        ]
  }

  render() {
    return <UberTable<Announcement>
      rowDefinition={this.rowDefinition}
      searchType='announcement'
      {...this.props}
    />
  }
}

export default AnnouncementsTable
