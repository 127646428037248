import * as React from 'react';
import Dropzone from 'react-dropzone';
declare const I18n;

import { Lesson } from '../../../../types';

import Header from '../../../Header';
import BreadCrumb from '../../../BreadCrumb';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';
import NoteInput from '../../../inputs/NoteInput';

import api from '../../../../api';

type Props = {
  initialData: {
    lesson?: Lesson,
    courseId: number,
    courseTitle?: string,
    numberOfLessons?: number,
  }
}

const CreateLesson: React.FunctionComponent<Props> = ({
  initialData: {
    lesson,
    courseId,
    courseTitle,
    numberOfLessons,
  },
}: Props): JSX.Element => {
  const createSection = (section = null) => {
    // if it has a section then it is an update
    const newSection = ({
      title: section ? section.title : '',
      errorTitle: '',
      content: section ? section.content : '',
      errorContent: '',
      questions_attributes: [],
      id: section ? section.id : null,
    });

    if (!section) {
      newSection.questions_attributes = [];
    } else {
      section.questions.forEach((question) => {
        const newQuestion = {
          title: question.title,
          error: '',
          id: question.id,
        };

        newSection.questions_attributes.push(newQuestion);
      });
    }

    return newSection;
  };

  const [title, setTitle] = React.useState('');
  const [errorTitle, setErrorTitle] = React.useState('');

  const [status, setStatus] = React.useState(0);

  const [overview, setOverview] = React.useState('');
  const [errorOverview, setErrorOverview] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const [sections, setSections] = React.useState([]);

  React.useEffect(() => {
    if (lesson) {
      // if it has a lesson then it is an update
      setTitle(lesson.title);
      setStatus(lesson.active ? 1 : 0);
      setOverview(lesson.overview);

      const newSections = [];

      lesson.sections.forEach((section, index) => newSections.push(createSection(section)));
      setSections(newSections);
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    let error = false;
    let errorSections = false;
    const handleSections = sections;

    if (!(title && title.trim())) {
      setErrorTitle("This field can't be blank");
      setTitle('');
      error = true;
    }

    if (!(overview && overview.trim())) {
      setErrorOverview("This field can't be blank");
      setOverview('');
      error = true;
    }

    // handle errors in sections
    sections.forEach((section, index) => {
      if (!(section.title && section.title.trim()) && !section._destroy) {
        handleSections[index].errorTitle = "This field can't be blank";
        error = true;
        errorSections = true;
      }

      if (!(section.content && section.content.trim()) && !section._destroy) {
        handleSections[index].errorContent = "This field can't be blank";
        error = true;
        errorSections = true;
      }

      section.questions_attributes.forEach((input, ind) => {
        if (!(input.title && input.title.trim()) && !input._destroy) {
          handleSections[index].questions_attributes[ind].error = "This field can't be blank";
          error = true;
          errorSections = true;
        }
      });
    });

    if (errorSections) {
      scrollOnError();
      setSections([...handleSections]);
    }

    console.log("ERROR", error);
    if (error) {
      scrollOnError();
      setLoading(false);
    } else {
      const sections_attributes = sections;

      sections_attributes.forEach((section) => {
        delete section['errorContent'];
        delete section['errorTitle'];

        section.questions_attributes.forEach((question) => delete question['error']);
      });

      const orderNumber = lesson ? lesson.order : numberOfLessons;
      const body = {
        lesson: {
          title: title.trim(),
          overview: overview,
          user_type: 'Admin',
          sections_attributes: sections_attributes,
          active: !!status,
          order: orderNumber,
        },
      };

      if (lesson) {
        api.updateLesson(courseId, lesson.id, body)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      } else {
        api.createLesson(courseId, body)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      }
    }
  }

  const scrollOnError = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const setValues = (
    index,
    content,
    error,
    value,
  ) => {
    const newSections = sections;

    if (typeof content === 'object') {
      newSections[index][content[0]][content[1]][content[2]] = value;
      newSections[index][error[0]][error[1]][error[2]] = '';
    } else {
      newSections[index][content] = value;
      newSections[index][error] = '';
    }

    setSections([...newSections]);
  }

  const handleDeleteInput = (index, ind) => {
    const newSections = sections;

    if (lesson) {
      newSections[index].questions_attributes[ind]._destroy = true;
    } else {
      newSections[index].questions_attributes.splice(ind, 1);
    }

    setSections([...newSections]);
  }

  const handleDeleteSection = (index) => {
    const newSections = sections;

    if (lesson) {
      newSections[index]._destroy = true;
    } else {
      newSections.splice(index, 1);
    }

    setSections([...newSections]);
  }

  return (
    <div className="lessons_new watch_form_changes">
      <div className="lessons_new__container">
        <Header
          title={I18n.t(lesson ? 'models.lesson.messaging.edit' : 'models.lesson.messaging.create')}
        />
        <BreadCrumb
          locations={[
            { title: I18n.t('models.course.attributes.courses'), url: '/admin/courses' },
            { title: lesson ? lesson.course_title : courseTitle, url: `/admin/courses/${courseId}/lessons` },
            { title: I18n.t(lesson ? 'models.lesson.messaging.edit' : 'models.lesson.messaging.create'), url: '' }
          ]}
        />

        <div className="col-md-12" style={{ marginTop: '60px' }}>
          <div className="row">
            <TextInput
              columnSize={8}
              error={errorTitle}
              value={title}
              type="lesson"
              field="title"
              label={I18n.t('models.lesson.attributes.name')}
              style={{ paddingLeft: 0 }}
              className="required"
              onChange={(value) => {
                setTitle(value);
                setErrorTitle('');
              }}
            />

            <SelectInput
              columnSize={4}
              value={status}
              type="lesson"
              field="status"
              label={I18n.t('models.lesson.attributes.status')}
              style={{ paddingRight: 0 }}
              onChange={(value) => setStatus(parseInt(value))}
            >
              <option key={0} value={0}>{I18n.t('models.course.status.inactive')}</option>
              <option key={1} value={1}>{I18n.t('models.course.status.active')}</option>
            </SelectInput>
          </div>
        </div>

        <NoteInput
          error={errorOverview}
          value={lesson ? lesson.overview : null}
          type="lesson"
          field="overview"
          label={I18n.t('models.lesson.attributes.content')}
          style={{ padding: 0, marginTop: '30px' }}
          required
          onChange={(text) => {
            setOverview(text);
            setErrorOverview('');
          }}
          toolbarOptions={[
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['codeview']],
          ]}
        />
      </div>

      {
        sections.map((section, index) => (
          <div
            style={{ width: '100%' }}
            key={index}
          >
            {
              !(section._destroy) && (
                <div className="lessons_new__container--big">
                  <div className="lessons_new__content-section">
                    <div className="lessons_new__section app-text">{I18n.t('views.lessons.index.section.header')}</div>
                    <div
                      id={`delete_${index}`}
                      onClick={() => handleDeleteSection(index)}
                    >
                      <i className="fal fa-trash fa-lg lessons_new__icon" />
                    </div>
                  </div>

                  <div className="section">
                    <div className="row" style={{ marginTop: '25px' }}>
                      <TextInput
                        columnSize={12}
                        error={sections[index].errorTitle}
                        value={sections[index].title}
                        type="lesson"
                        field={`${index}[title]`}
                        label={I18n.t('views.lessons.index.section.title')}
                        className="required"
                        onChange={(value) => setValues(index, 'title', 'errorTitle', value)}
                      />
                    </div>
                  </div>

                  <NoteInput
                    error={sections[index].errorContent}
                    value={sections[index].content}
                    type="lesson"
                    field="content"
                    style={{ padding: 0, marginTop: '30px' }}
                    label={I18n.t('views.lessons.index.section.content')}
                    required
                    onChange={(value) => setValues(index, 'content', 'errorContent', value)}
                    toolbarOptions={[
                      ['style', ['style']],
                      ['font', ['bold', 'italic', 'underline', 'clear']],
                      ['para', ['ul', 'ol', 'paragraph']],
                      ['table', ['table']],
                      ['insert', ['link', 'picture', 'video']],
                      ['view', ['codeview']],
                    ]}
                  />

                  {
                    sections[index].questions_attributes.map((input, ind) => (
                      <div key={`${index}_${ind}`}>
                        {
                          !(sections[index].questions_attributes[ind]._destroy) && (
                            <div
                              className="col-md-12 lessons_new__input-container"
                            >
                              <div className="row">
                                <TextInput
                                  columnSize={11}
                                  error={sections[index].questions_attributes[ind].error}
                                  value={sections[index].questions_attributes[ind].title}
                                  type="lesson"
                                  field={`${ind}[name]`}
                                  label={I18n.t('models.lesson.messaging.input')}
                                  className="required"
                                  onChange={(value) => {
                                    setValues(
                                      index,
                                      ['questions_attributes', ind, 'title'],
                                      ['questions_attributes', ind, 'error'],
                                      value,
                                    );
                                  }}
                                />

                                <div className="col-md-1 lessons_new__input-icon" style={{ paddingRight: 0 }}>
                                    <div
                                      id={`delete_${index}_${ind}`}
                                      onClick={() => handleDeleteInput(index, ind)}
                                    >
                                      <i className="fal fa-trash fa-lg list-item__icon--title" />
                                    </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ))
                  }

                  <div
                    className="lessons_new__button-container"
                    onClick={() => {
                      const newSections = sections;
                      newSections[index].questions_attributes.push({ title: '', error: '' });

                      setSections([...newSections]);
                    }}
                    style={{ marginTop: '15px', padding: '5px', fontSize: '24px' }}
                  >
                    <i className="far fa-plus-circle mr-2" />
                    <div className="lessons_new__button-text app-text">{I18n.t('views.lessons.index.section.add_input')}</div>
                  </div>
                </div>
              )
            }
          </div>
        ))
      }

      <div
        className="lessons_new__button-container"
        onClick={() => {
          const newSections = sections;

          newSections.push(createSection());
          setSections([...newSections]);
        }}
        style={{ marginBottom: '40px', fontSize: '24px' }}
      >
        <i className="far fa-plus-circle mr-2" />
        <div className="lessons_new__button-text app-text">{I18n.t('views.lessons.index.section.add_section')}</div>
      </div>

      <div
        className="col-12 text-center"
        style={{ paddingTop: '40px', borderTop: 'solid 1px #CDCDCD' }}
      >
        <button
          type="submit"
          name="commit"
          disabled={loading}
          className="btn btn-primary pull-right"
          onClick={handleSubmit}
        >
          {I18n.t('views.forms.submit')}
          { loading && <i className='far fa-spinner-third fa-spin btn-loader ml-2'></i> }
        </button>
      </div>
    </div>
  );
}

export default CreateLesson;
