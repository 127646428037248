import * as React from 'react';
declare const I18n;
import { Collapse } from 'react-collapse';
import DOMPurify from 'dompurify/dist/purify.min';

import { Resource } from '../../../../types';

import Filter from 'bad-words';
import Header from '../../../Header';
import BreadCrumb from '../../../BreadCrumb';
import Section from '../../../Section';

type Props = {
  initialData: {
    resource: Resource,
    resourceCategoryId: number,
    resourceCategoryTitle: string
  },
}

const ShowLesson: React.FunctionComponent<Props> = ({ initialData: { resource, resourceCategoryId, resourceCategoryTitle } }: Props): JSX.Element => {
  const [sectionsArray, setSectionsArray] = React.useState([]);
  const DOMPurifyConfig = {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['target']
  };

  React.useEffect(() => {
    const sectionArray = resource.sections.map((section) => {
      return {
        isOpen: false
      };
    });

    setSectionsArray(sectionArray);
  }, []);

  return (
    <div className="lessons_show">
      <Header title={resource.title}/>

      <BreadCrumb
        locations={[
          { title: I18n.t('models.resource_category.attributes.categories'), url: '/explore_categories' },
          { title: resourceCategoryTitle, url: `/explore_categories/${resourceCategoryId}/resources` },
          { title: resource.title, url: '' },
        ]}
      />

      <div className="lessons_show__body">
        <Section
          title={I18n.t('views.common.overview')}
        />

        <div
          className="section__body app-text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resource.body, DOMPurifyConfig) }}
        />

        {
          resource.sections.map((section, index) => {
            return (
              <div
                className="lessons_show__section"
                key={section.id}
              >
                <Section
                  title={section.title}
                  withInput={section.questions.length > 0}
                  withBorder
                  index={index+1}
                  iconId={section.id}
                  onArrowPress={() => {
                    const newArray = sectionsArray;

                    newArray[index].isOpen = !newArray[index].isOpen;

                    setSectionsArray([...newArray]);
                  }}
                />

                <Collapse isOpened={sectionsArray[index] && sectionsArray[index].isOpen}>
                  <div
                    className="section__body app-text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content, DOMPurifyConfig) }}
                  />
                </Collapse>
              </div>
            )}
          )
        }
      </div>
    </div>
  );
}

export default ShowLesson;
