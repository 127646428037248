declare const I18n
declare const axios
axios.defaults.timeout = 10000;

import * as React from 'react'
import dig from 'object-dig'
import TagPill from '../TagPill'
import { Tag } from '../../types'

const debounce = require('lodash/debounce')
const qs = require('qs')

export type PaginationMetadata = {
  items: number
  pages: number
  page: number
}

export type RowDefinition<T> = {
  title: string
  attribute_key: string
  sort_key?: string
  textAlignment?: 'center'
  render?: (object: T) => JSX.Element | string
}

export type CtaDefinition = {
  text: string
  url: string
}

export type AdditionalOptions = (T) => JSX.Element

type SortDirection = 'asc' | 'desc'

type Props<T> = {
  rowDefinition: RowDefinition<T>[]
  additionalOptions?: AdditionalOptions
  searchType: string
  showTags?: boolean
  conditions?: {}
  includeDeleted?: boolean
  omitOptions?: boolean
  cta?: CtaDefinition
  show_active_status_option?: boolean
  is_admin?: boolean
  sort_key?: string
  per_page?: number | false
}

type State<T> = {
  firstFetched: boolean,
  results: T[],
  metadata: PaginationMetadata
  filterQuery: string
  filterTags: Tag[],
  sortDirection: SortDirection
  sortKey: string
  perPage: number | false
  searchType: string
  showTags?: boolean
  loading: boolean
  error: boolean,
  colSpanCount: number
}

export class UberTable<T> extends React.Component<Props<T>, State<T>> {
  constructor (props: Props<T>) {
    super(props)

    this.state = {
      firstFetched: false,
      results: [],
      searchType: this.props.searchType,
      showTags: this.props.showTags,
      metadata: {
        items: 0,
        pages: 0,
        page: 1
      } as PaginationMetadata,
      filterQuery: '',
      filterTags: [],
      sortDirection: 'desc',
      sortKey: this.props.sort_key || '',
      perPage: this.props.per_page || false,
      loading: false,
      error: false,
      colSpanCount: this.props.rowDefinition.length
        + Number(!this.props.omitOptions ? 1 : 0)
        + Number(this.props.showTags ? 1 : 0)
    }
  }

  componentDidMount () {
    this.fetchPage()
  }

  attributeValue (record, attribute_key: string) {
    let keys = attribute_key.split('.')
    return dig(record, ...keys)
  }

  movePage (delta: number) {
    let pageNumber: number
    const requestedPage = this.state.metadata.page + delta

    if (requestedPage < 1) {
      pageNumber = 1
    } else if (requestedPage >= this.state.metadata.pages) {
      pageNumber = this.state.metadata.pages
    } else {
      pageNumber = requestedPage
    }

    if (pageNumber == this.state.metadata.page) return

    this.fetchPage(requestedPage)
  }

  gotoPage (pageNumber: number) {
    const delta = pageNumber - this.state.metadata.page
    this.movePage(delta)
  }

  async fetchPage (page?: number) {
    this.setState({
      loading: true
    })

    let pageNumber = page || this.state.metadata.page

    try {
      let params = {
        type: this.state.searchType,
        page: pageNumber,
      } as any

      if (this.state.perPage) {
        params.per_page = this.state.perPage
      }

      if (this.state.sortKey) {
        params.order_by = this.state.sortKey
        params.order_dir = this.state.sortDirection
      }

      if (this.state.filterQuery) {
        params.query = this.state.filterQuery
      }

      if (this.state.filterTags.length > 0) {
        params.tags = this.state.filterTags.map((t) => t.name).join(',')
      }

      if (this.props.conditions) {
        params.conditions = this.props.conditions
      }

      if (this.props.includeDeleted) {
        params.include_deleted = true
      }

      let queryParams = qs.stringify(params, { encode: false });
      let url = `/search?${queryParams}`

      const request = await axios.get(url)
      const response = request.data

      this.setState({
        firstFetched: true,
        results: response.results,
        metadata: response.metadata
      })
    } catch (err) {
      this.setState({ error: true })
      console.log(`UberTable fetchPage: `, err)
    }

    this.setState({
      loading: false
    })
  }

  filterCollection = debounce(function (query: string)  {
    this._filterCollection(query)
  }, 300)

  _filterCollection (query: string) {
    this.setState({
      filterQuery: query
    }, () => {
      this.fetchPage()
    })
  }

  sortCollection (attribute_key: string, direction: SortDirection) {
    this.setState({
      sortDirection: direction,
      sortKey: attribute_key
    }, () => {
      this.fetchPage()
    })
  }

  invertSortDirection () {
    return this.state.sortDirection == 'asc' ? 'desc' : 'asc'
  }

  filterByTag (tag) {
    this.setState({
      filterTags: this.state.filterTags.concat([tag])
    }, () => {
      this.fetchPage()
    })
  }

  removeFilterTag (tag) {
    this.setState({
      filterTags: this.state.filterTags.filter((other_tag) => other_tag.name !== tag.name)
    }, () => {
      this.fetchPage()
    })
  }

  emptyPermissions (object) {
    return !(this.canView(object) || this.canEdit(object) || this.canDelete(object))
  }

  canView (object) {
   return object.permissions && object.permissions.view && object.view_url
  }

  canEdit (object) {
    return !object.is_deleted && object.permissions && object.permissions.edit && (object.edit_url || object.admin_edit_url)
  }

  canDelete (object) {
    return !object.is_deleted && object.permissions && object.permissions.delete && object.delete_url
  }

  renderTableHead() {
    let columnHeaders = this.props.rowDefinition.map((defn) => {
      return (
        <th key={`head-${defn.attribute_key}`} className={defn.textAlignment ? 'text-center' : ''}
          onClick={() => defn.sort_key ? this.sortCollection(defn.sort_key || defn.attribute_key, this.invertSortDirection()) : ''}>
          {defn.title}
          {
            defn.sort_key
              ? defn.sort_key == this.state.sortKey
                ? this.state.sortDirection == 'asc' ? <i className='fa fa-sort-up' /> : <i className='fa fa-sort-down' />
                : <i className='fa fa-sort'/>
              : ''
          }
        </th>
      )
    })

    if (this.props.showTags) {
      columnHeaders.push(<th key={`head-tags-list`}>{I18n.t('models.tag.tags')}</th>)
    }

    if (!this.props.omitOptions) {
      columnHeaders.push(<th key={`head-options`} className='text-center'>{I18n.t('views.common.options')}</th>)
    }

    return (
      <thead>
        { this.tagFilterRow({ colSpan: columnHeaders.length }) }
        <tr>{ columnHeaders }</tr>
      </thead>
    )
  }

  tagFilterRow (config) {
    if (this.state.filterTags.length === 0) return

    return (
      <tr>
        <td colSpan={config.colSpan}>
          <div className='tags-filter-row'>
            <span>Filtering by tags: </span>
            {
              this.state.filterTags.map((tag) => {
                return <TagPill key={tag.name} tag={tag} canDelete={true} onClick={() => { this.removeFilterTag(tag) }} />
              })
            }
          </div>
        </td>
      </tr>
    )
  }

  objectToRow (object) {
    let rowCells = this.props.rowDefinition.map((defn) => {
      return (
        <td key={`${object.type}-${object.id}-${defn.attribute_key}`} className={defn.textAlignment ? 'text-center' : ''}>
          { defn.render ? defn.render.bind(this)((object)) : this.attributeValue(object, defn.attribute_key) }
        </td>
      )
    })

    if (this.props.showTags) {
      const tagList = object.tags.map((tag) => {
        return <TagPill tag={tag} key={tag.name} onClick={() => { this.filterByTag(tag) }}/>
      })

      rowCells.push(
        <td key={`${object.type}-${object.id}-tags-list`}>
          { tagList }
        </td>
      )
    }

    if (!this.props.omitOptions) {
      rowCells.push(
        <td key={`${object.type}-${object.id}-options`} className='text-center'>
          <div className="table-row-options">
            {
              this.emptyPermissions(object)
              ?
                <i className="fas fa-horizontal-rule text-muted"></i>
              :
                ''
            }

            {
              this.canView(object)
              ?
                <a href={object.view_url}>
                  <i className="table-row-options-button fal fa-file-search" title="View"></i>
                </a>
              :
                ''
            }

            {
              this.canEdit(object)
              ?
                <a href={this.props.is_admin && object.admin_edit_url ? object.admin_edit_url : object.edit_url}>
                  <i className="table-row-options-button fal fa-edit" title="Edit"></i>
                </a>
              :
                ''
            }

            {
              this.canDelete(object)
              ?
                <a data-confirm={I18n.t('views.forms.messaging.confirm_delete')} rel="nofollow" data-method="delete" href={this.props.is_admin && object.admin_delete_url ? object.admin_delete_url : object.delete_url}>
                  <i className="table-row-options-button fal fa-trash-alt" title="Delete" aria-hidden="true"></i>
                </a>
              :
                ''
            }

            { this.props.additionalOptions && this.props.additionalOptions(object) }

          </div>
        </td>
      )
    }

    return rowCells
  }

  renderTableRow(object) {
    return (
      <tr key={`${object.type}-${object.id}`}>
        { this.objectToRow(object) }
      </tr>
    )
  }

  renderTableBody() {
    return ( this.state.results.length
      ? this.state.results.map((object) => this.renderTableRow(object))
      : <tr><td colSpan={this.state.colSpanCount} className='text-center'>
          { this.state.firstFetched ? I18n.t('views.common.no_results') : I18n.t('views.common.loading') }
        </td></tr>
    )
  }

  renderPagination () {
    return (
      <div className='uber-table-pagination'>
        {
          this.state.error
            ?
            ''
            :
            <nav aria-label="Table Pagination">
              <ul className="pagination">
                <li className="page-item" onClick={() => this.movePage(-1)}><a className="page-link" href="#" aria-label="prev">‹ {I18n.t('views.common.prev')}</a></li>

                {
                  [...Array(this.state.metadata.pages)].map((_, index) => {
                    return (
                      <li className={`page-item ${this.state.metadata.page === index + 1 ? 'active' : ''}`} key={`page${index}`}>
                        <div className="page-link" onClick={() => { this.gotoPage(index + 1)}}>
                          { index + 1 }
                        </div>
                      </li>
                    )
                  })
                }

                <li className="page-item" onClick={() => this.movePage(1)}><a className="page-link" href="#" aria-label="next">{I18n.t('views.common.next')} ›</a></li>
              </ul>
            </nav>
        }
      </div>
    )
  }

  render () {
    return (
      <div className="uber-table">
        <div className="uber-table-options">
          <div className='uber-table-options-search'>
            <label htmlFor='table-query'>{I18n.t('views.common.search')}</label>
            <div className="input-group">
              <input type='text' className="form-control" onChange={(e) => this.filterCollection(e.target.value)} />
              <div className="input-group-append">
                { this.state.loading ? <i className="far fa-spinner-third fa-spin"></i> : <i className="fa fa-search"></i> }
              </div>
            </div>
          </div>
          {
            this.props.cta
            &&
            <div className="uber-table-options-cta">
              <a href={this.props.cta.url} className="btn btn-primary">{this.props.cta.text}</a>
            </div>
          }
        </div>

        <table className='table'>
          { this.renderTableHead() }

          <tbody>
            {
              this.state.error
              ? <tr key='errorRow'><td colSpan={this.state.colSpanCount} className='text-center'>{ I18n.t('views.forms.messaging.error_reload') }</td></tr>
              : this.renderTableBody()
            }
          </tbody>
        </table>

        {this.renderPagination()}
      </div>
    )
  }
}