import * as React from 'react';

import "./index.scss";

type Props = {
  withInput?: boolean,
  withBorder?: boolean,
  isOpen?: boolean,
  title: string,
  onArrowPress?(): void,
  iconId?: number,
  index?: number
}

const Section: React.FunctionComponent<Props> = ({
  withInput,
  withBorder,
  isOpen,
  title,
  onArrowPress,
  iconId,
  index,
}: Props): JSX.Element => {
  const iconRef = React.createRef<any>()

  const toggleSection = (id) => {
    if (onArrowPress) {
      onArrowPress()
    }

    if (iconRef.current) {
      iconRef.current.classList.contains('active')
        ? iconRef.current.classList.remove('active')
        : iconRef.current.classList.add('active')
    }
  }

  return (
    <div id={`section_${index}`} className="section" onClick={toggleSection}>
      <div
        className="section__title app-text"
        style={{
          borderBottom: withBorder ? 'solid 1px #813091' : '',
          paddingBottom: withBorder ? '30px' : 0,
        }}
      >
        {title}
        {
          withBorder && (
            <div id={`section_arrow_${index}`}>
              <i ref={iconRef} className="fal fa-angle-down section__icon"/>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Section;
