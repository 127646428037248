declare const axios: any

import * as React from 'react'
import Autosuggest from 'react-autosuggest';

type Props = {
  type: string
  display_key: string
  input_id?: string,
  multiple?: boolean
  placeholder: string
  onSelected?: Function
}

type State = {
  suggestions: {}[]
  value: string
  isLoading: boolean
  error: boolean
  input?: HTMLInputElement
  selected: {} | {}[] | null
}

class TypeAhead extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    let state = {
      suggestions: [],
      value: '',
      isLoading: false,
      error: false,
      selected: null
    } as State

    if (this.props.input_id) {
      state.input = document.querySelector(this.props.input_id)
     }

    this.state = state
  }

  suggestionValue (suggestion) {
    return suggestion[this.props.display_key]
  }

  onChange (_, { newValue }) {
    this.setState({
      value: newValue
    })
  }

  async fetchSuggestions ({ value }) {
    this.setState({ isLoading: true })

    let params = [
      `type=${this.props.type}`,
      `query=${value}`
    ]

    let queryString = params.join('&')
    let url = `/search?${queryString}`

    try {
      let request = await axios.get(url)
      let response = request.data

      this.setState({
        isLoading: false,
        suggestions: response.results
      })
    } catch (err) {
      this.setState({
        isLoading: true,
        error: true
      })
      console.log(`UberTable fetchPage: `, err)
    }
  }

  suggestionSelected (event, { suggestion, /*suggestionValue, suggestionIndex, sectionIndex, method*/ }) {
    event.stopPropagation()
    event.preventDefault()

    if (this.props.onSelected) this.props.onSelected(suggestion)
    if (this.state.input) this.state.input.value = suggestion.id
  }

  renderSuggestion (suggestion, { query, isHighlighted }) {
    let value = this.suggestionValue(suggestion)
    value = value.replace(new RegExp(query, 'i'), '<strong>$&</strong>')


    return (
      <div className={isHighlighted ? 'highlighted' : ''} dangerouslySetInnerHTML={{__html: value}} />
    )
  }

  clearSuggestions () {
    this.setState({
      isLoading: false,
      suggestions: []
    })
  }

  render () {
    const inputProps = {
      placeholder: this.props.placeholder,
      value: this.state.value,
      onChange: this.onChange.bind(this)
    }

    return (
      <Autosuggest
        // alwaysRenderSuggestions={true}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.fetchSuggestions.bind(this)}
        onSuggestionSelected={this.suggestionSelected.bind(this)}
        onSuggestionsClearRequested={this.clearSuggestions.bind(this)}
        getSuggestionValue={this.suggestionValue.bind(this)}
        renderSuggestion={this.renderSuggestion.bind(this)}
        inputProps={inputProps}
      />
    )
  }
}

export default TypeAhead