declare const axios: any
declare const I18n;

import * as React from 'react'
import Autosuggest from 'react-autosuggest';

type Props = {
  type: string
  display_key: string
  inputId?: string,
  nameInputId?: string,
  multiple?: boolean
  placeholder: string
  onSelected?: Function
  initialValue?: string,
  showChangeButton?: boolean,
  error?: boolean
  confirmChangeMessage?: string,
  isAdmin?: boolean,
  isLastChampion?: boolean
}

type State = {
  suggestions: {}[]
  value: string
  isLoading: boolean
  error: boolean
  idInput?: HTMLInputElement
  nameInput?: HTMLInputElement
  selected: {} | {}[] | null,
  selectionMade: boolean,
  showChangeButton: boolean,
  confirmChangeMessage?: string,
  isAdmin?: boolean,
  isLastChampion?: boolean
}

class OrganizationTypeAhead extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    let state = {
      suggestions: [],
      value: this.props.initialValue || '',
      isLoading: false,
      error: false,
      selected: null,
      selectionMade: this.props.initialValue ? true : false,
      showChangeButton: this.props.showChangeButton ?? true,
      confirmChangeMessage: this.props.confirmChangeMessage ?? null,
      isAdmin: this.props.isAdmin ?? false,
      isLastChampion: this.props.isLastChampion ?? false,
    } as State

    if (this.props.inputId) {
      state.idInput = document.querySelector(this.props.inputId)
    }

    if (this.props.nameInputId) {
      state.nameInput = document.querySelector(this.props.nameInputId)
    }

    this.state = state
  }

  componentDidMount () {
    if (this.state.value && this.state.selectionMade) {
      this.toggleInputDisabled(false)
    }
  }

  suggestionValue (suggestion) {
    return suggestion[this.props.display_key]
  }

  onChange (_, { newValue }) {
    this.setState({
      value: newValue
    })

    if (this.state.nameInput) this.state.nameInput.value = newValue
    if (this.state.idInput) this.state.idInput.value = ''

    if (!newValue) {
      setTimeout(() => {
        this.toggleInputDisabled(true)
      }, 100);
    }

    let confirmChangeButton = document.getElementById('org-info-update');
    if (this.state.confirmChangeMessage !== null && confirmChangeButton) {
      confirmChangeButton.setAttribute('data-confirm', this.state.confirmChangeMessage)
    }
  }

  async fetchSuggestions ({ value }) {
    this.setState({ isLoading: true })


    let params = [
      `type=${this.props.type}`,
      `query=${value}`
    ]

    let queryString = params.join('&')
    let url = `/search?${queryString}`

    if (! this.state.isLoading) {
      try {
        let request = await axios.get(url)
        let response = request.data
        let filteredResults = []

        // Filter out "hackergal at home" organization from the list.
        if (!this.state.isAdmin) {
          filteredResults = response.results.filter(organization => organization.is_at_home == false)
                              .map(filteredOrganization => (filteredOrganization))
        } else {
          filteredResults = response.results.map(filteredOrganization => (filteredOrganization))
        }

        this.setState({
          isLoading: false,
          suggestions: filteredResults
        })
      } catch (err) {
        this.setState({
          isLoading: true,
          error: true
        })
        console.log(`UberTable fetchPage: `, err)
      }
    }
  }

  suggestionSelected (event, { suggestion, /*suggestionValue, suggestionIndex, sectionIndex, method*/ }) {
    event.stopPropagation()
    event.preventDefault()

    this.setState({
      selectionMade: true
    })

    this.toggleInputDisabled(false)

    if (this.props.onSelected) this.props.onSelected(suggestion)
    if (this.state.idInput) this.state.idInput.value = suggestion.id
    if (this.state.nameInput) this.state.nameInput.value = ''

    let newOrganizationFields = document.getElementsByClassName('new-organization-fields')

    for(var x=0; x < newOrganizationFields.length; x++) {
      newOrganizationFields[x].classList.add('hidden')
    }
  }

  renderSuggestion (suggestion, { query, isHighlighted }) {
    let value = this.suggestionValue(suggestion)
    value = value.replace(new RegExp(query, 'i'), '<strong>$&</strong>')

    return (
      <div className={isHighlighted ? 'highlighted' : ''} dangerouslySetInnerHTML={{__html: value}} />
    )
  }

  clearSuggestions () {
    this.setState({
      isLoading: false,
      suggestions: []
    })

    setTimeout(() => {
      if (this.state.value && this.state.selectionMade) {
        this.toggleInputDisabled(false)
      }
    }, 100);

  }

  toggleInputDisabled(enable: boolean) {
    let typeAheadInput = document.querySelector('.react-autosuggest__input')

    if (typeAheadInput) {
      if (enable) {
        typeAheadInput.removeAttribute('disabled')
      } else {
        typeAheadInput.setAttribute('disabled', 'true')
      }
    }
  }

  resetInput () {
    this.setState({
      selectionMade: false,
      value: "",
    })

    setTimeout(() => {
      this.clearSuggestions()
    }, 100);

    this.toggleInputDisabled(true)

    if (this.state.nameInput) this.state.nameInput.value = ''
    if (this.state.idInput) this.state.idInput.value = ''

    let newOrganizationFields = document.getElementsByClassName('new-organization-fields')

    for(var x=0; x < newOrganizationFields.length; x++) {
      newOrganizationFields[x].classList.remove('hidden')
    }
  }

  render () {
    const inputProps = {
      placeholder: this.props.placeholder,
      value: this.state.value,
      onChange: this.onChange.bind(this),
      className: `form-control organization-typeahead ${this.state.error || this.props.error ? 'is-invalid' : ''}`
    }

    return (
      <div>
        <Autosuggest
          // alwaysRenderSuggestions={true}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.fetchSuggestions.bind(this)}
          onSuggestionSelected={this.suggestionSelected.bind(this)}
          onSuggestionsClearRequested={this.clearSuggestions.bind(this)}
          getSuggestionValue={this.suggestionValue.bind(this)}
          renderSuggestion={this.renderSuggestion.bind(this)}
          inputProps={inputProps}
        />
        { !this.state.showChangeButton &&
          <small className="form-text text-muted">{I18n.t('models.organization.messaging.change_organization_name')}</small>
        }
        { this.state.showChangeButton && this.state.selectionMade && !this.state.isLoading &&
          <span id="change-organization" onClick={() => this.resetInput()}>{I18n.t('models.organization.messaging.change_organization')}</span>
        }
        <span></span>
      </div>
    )
  }
}

export default OrganizationTypeAhead
