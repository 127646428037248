import * as React from 'react'
// import * as ReactDOM from 'react-dom'

type Props = {
  image_url: string
  update_url: string
}

class ProfilePicker extends React.Component<Props> {
  render () {
    return (
      <div className="profile-picker">
        <img src={this.props.image_url} />
      </div>
    )
  }
}

export default ProfilePicker