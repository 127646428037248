import * as React from 'react'
import { UberTable, RowDefinition } from './UberTable'
import { MediaFile } from '../../types'
declare const I18n

type Props = { }

class MediaFilesTable extends React.Component<Props> {
  private rowDefinition: RowDefinition<MediaFile>[]

  constructor(props: Props) {
    super(props)

    this.rowDefinition = [
      {
        title: I18n.t('models.common.name'),
        attribute_key: 'name',
        sort_key: 'name',
        render: (file: MediaFile) => {
          return <div className="media-file-table-row">
            <a href={file.file_url} target="_blank" title="File Url">
            { this.isPDF(file.name)
              ? <div className="media-file-icon"><i className="fal fa-file-pdf"></i></div>
              : <div className="media-file-thumbnail"><img src={file.thumbnail_url} /></div>
            }
            </a>
            <a href={file.file_url} target="_blank" title="File Url">
              {file.name}
            </a>
          </div>
        }
      },
      {
        title: I18n.t('models.media_file.attributes.file_url'),
        attribute_key: 'file_url',
        render: (file: MediaFile) => {
          return (
            <div className="btn btn-sm btn-primary copy-to-clipboard" data-original-title={I18n.t('views.common.copied')} data-clipboard-text={file.file_url} data-toggle="manual">
              { I18n.t('views.forms.copy') }
            </div>
          )
        }
      },
    ]
  }

  isPDF (fileName) {
    return fileName.includes('.pdf')
  }

  render() {
    return <UberTable<MediaFile>
      rowDefinition={this.rowDefinition}
      searchType='media_file'
      {...this.props}
    />
  }
}

export default MediaFilesTable
