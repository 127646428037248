import * as React from 'react';

import "./index.scss";

type Props = {
  title: string,
  value: string,
  error?: boolean,
  inputId: string,
  isEdit: boolean,
  isFacilitator: boolean,
  onChange(value: string): void,
  onBlur?(value: string): void,
}

const Question: React.FunctionComponent<Props> = ({
  title,
  value,
  error,
  inputId,
  isEdit,
  isFacilitator,
  onChange,
  onBlur,
}: Props): JSX.Element => (
  <div className="question">
    <div className="question__title app-text">{title}</div>
    <textarea
      className={`form-control question__input ${error ? 'is-invalid' : ''}`}
      defaultValue={value}
      onChange={(event) => onChange(event.target.value)}
      onBlur={(event) => onBlur(event.target.value)}
      id={inputId}
      disabled={isEdit || isFacilitator}
    />
    {error && (
      <div className="invalid-feedback d-block">
        {error}
      </div>
    )}
  </div>
);

export default Question;
