// This script will identify if any inputs or summernote WYSIWYG editors have been modified by the user
// and unless they click the submit button on the form, they will be warned they might lose data if they continue.

document.addEventListener("DOMContentLoaded", function() {
  if ($('.watch_form_changes').length > 0) {
    var formChangeFlag = false;
    $('.watch_form_changes').on('change keydown keyup', ':input', function(e){
      formChangeFlag = true;
    });

    $('input[type=submit], button[type=submit]').click(function(e){
      formChangeFlag = false;
    });

    $( window ).on('beforeunload', function() {
      if(formChangeFlag === true){
        return 'Changes you made may not be saved.';
      }
    });
  }
})
