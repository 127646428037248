import * as React from 'react';
declare const I18n;

type Props = {
  value: string|number,
  error?: string,
  type: string,
  field: string,
  label: string,
  className?: string,
  columnSize: number,
  style?: object,
  onChange(value: string): void,
  children: React.ReactNode,
}

const SelectInput: React.FunctionComponent<Props> = ({
  value,
  error,
  type,
  field,
  label,
  className,
  columnSize,
  onChange,
  style,
  children,
}: Props): JSX.Element => (
  <div className={`col-md-${columnSize}`} style={style}>
    <div className="form-group">
      <label className={className} htmlFor={`${type}_${field}`}>{label}</label>
      <select
        className={`form-control ${error ? 'is-invalid' : ''}`}
        id={`${type}_${field}_id`}
        name={`${type}[${field}]`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {children}
      </select>
      {error && (
          <div className="invalid-feedback d-block">
            {error}
          </div>
        )}
    </div>
  </div>
);

export default SelectInput;
