document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector('form')) {
    document.querySelector('form').addEventListener('submit', function (e) {
      var form = e.target

      var button = form.querySelector('button[type=submit]')

      if (button) {
        button.setAttribute('disabled', true)

        var spinner = document.createElement('i')
        spinner.classList.add('far', 'fa-spinner-third', 'fa-spin', 'btn-loader')

        button.appendChild(spinner)
      }
    })
  }
})
