import * as React from 'react';
import _ from 'lodash';
declare const I18n;

import { Resource } from '../../../../types';

import Header from '../../../Header';
import ResourceListItem from '../../components/list_item/index'
import ResourceCardItem from '../../components/card_item/index'
import BreadCrumb from '../../../BreadCrumb';

import api from '../../../../api';

type Props = {
  initialData: {
    resourceCategoryId: number,
    resourceCategoryTitle?: string,
    resourceCategorySubtitle?: string,
    resources: Resource[],
    userType?: string,
  },
}

const IndexResource: React.FunctionComponent<Props> = ({
  initialData: {
    resourceCategoryId,
    resourceCategoryTitle,
    resourceCategorySubtitle,
    resources,
    userType,
  } }: Props): JSX.Element => {
  const isAdmin = !(userType && (userType === 'Learner' || userType === 'Facilitator'));

  const [displayType, setDisplayType] = React.useState('card');

  const handleDelete = (id) => {
    if (confirm(I18n.t('views.forms.messaging.confirm_delete'))) {
      api.deleteResource(resourceCategoryId, id)
        .then((res) => window.location.href = res.url)
        .catch((error) => console.error('Error', error));
    }
  };

  return (
    <div className="resource_index">
      <Header
        title={resourceCategoryTitle}
        titleButton={I18n.t('models.resource.messaging.create')}
        locationButton={`${window.location.pathname}/new`}
        buttonDisabled={!isAdmin}
      />

      <BreadCrumb
        locations={[
          { title: I18n.t('models.resource_category.attributes.categories'), url: `${isAdmin ? '/admin' : ''}/explore_categories` },
          { title: resourceCategoryTitle, url: '' },
        ]}
      />

      {/* <ul>
        <li onClick={() => setDisplayType('card')} >Card View</li>
        <li onClick={() => setDisplayType('list')} >List View</li>
      </ul> */}
      <div className="courses_index__list">
        {
          resources.length > 0 ? (
            resources.map((resource, index) => (
              displayType == 'card' ?
                <ResourceCardItem
                index={index}
                resource={resource}
                resourceCategoryId={resourceCategoryId}
                handleDelete={() => handleDelete(resource.id)}
                isAdmin={isAdmin}
              /> :
              <ResourceListItem
                index={index}
                resource={resource}
                resourceCategoryId={resourceCategoryId}
                handleDelete={() => handleDelete(resource.id)}
                isAdmin={isAdmin}
              />
            ))
          ) : (
            <div className="lesson_index__empty-state app-text">{I18n.t('models.resource.messaging.empty_state')}</div>
          )
        }
      </div>
    </div>
  );
}
export default IndexResource;
