import * as React from 'react';
declare const I18n;

import { ResourceCategory, MediaFile } from '../../../../types';

import CardItem from '../../components/card_item';
import Header from '../../../Header';
import BreadCrumb from '../../../BreadCrumb';

import api from '../../../../api';

type Props = {
  initialData: {
    resource_categories: ResourceCategory[],
    image: MediaFile,
    userType?: string,
    userLevel?: number,
  }
}

const IndexResourceCategory: React.FunctionComponent<Props> = ({
  initialData: {
    resource_categories,
    userType,
    userLevel,
  } }: Props): JSX.Element => {
  const [resourceCategoryList, setResourceCategoryList] = React.useState(resource_categories);
  const isAdmin = !(userType && (userType === 'Learner' || userType === 'Facilitator'));

  const handleDelete = (id) => {
    if (confirm(I18n.t('views.forms.messaging.confirm_delete'))) {
      api.deleteResourceCategory(id)
        .then((res) => {
          if (! res.error) {
            setResourceCategoryList(resourceCategoryList.filter((item) => item.id !== id));
          }
          window.location.href = res.url;
        })
        .catch((error) => console.error('Error', error));
    }
  }

  return (
    <div className="resource_categories_index">
      <Header
        title={I18n.t('models.resource_category.attributes.category')}
        titleButton={I18n.t('models.resource_category.messaging.create')}
        locationButton="/admin/explore_categories/new"
        buttonDisabled={userType && (userType === 'Learner' || userType === 'Facilitator')}
      />

      <BreadCrumb
        locations={[
          { title: I18n.t('models.resource_category.attributes.categories'), url: null }
        ]}
      />

      <div className="courses_index__list">
      {
        resourceCategoryList.length > 0 ? (
          <>
            {
              resourceCategoryList.map((row, index) => (
                <CardItem
                  title={row.name}
                  description={row.description}
                  targetRole={row.target_role}
                  id={row.id}
                  index={index}
                  key={row.id}
                  image={row.cover_image_url ? row.cover_image_url : null}
                  handleDelete={handleDelete}
                  isFacilitator={userType && (userType === 'Facilitator')}
                  isLearner={userType && (userType === 'Learner')}
                />
              ))
            }
          </>
        ) : (
          <div className="courses_index__empty-state app-text">{I18n.t('models.resource_category.messaging.empty_state')}</div>
        )
      }
      </div>
    </div>
  );
}

export default IndexResourceCategory;
