declare const I18n

import * as React from 'react'
import { UberTable, RowDefinition, CtaDefinition } from './UberTable'
import { Facilitator } from '@/types'

type Props<T> = {
  permissions?: {
    view: boolean
    edit: boolean
    delete: boolean
    toggle_active_status: boolean
  }
  show_active_status_option: boolean
  is_admin: boolean
}

class FacilitatorsTable extends React.Component<Props<Facilitator>> {
  private rowDefinition: RowDefinition<Facilitator>[]

  constructor (props: Props<Facilitator>) {
    super(props)

    this.rowDefinition = [
      {
        title: I18n.t('models.common.name'),
        attribute_key: 'full_name',
        sort_key: 'first_name',
        render: (facilitator: Facilitator) => {
          let url = facilitator.permissions && facilitator.permissions.edit && !facilitator.is_deleted
            ? this.props.is_admin ? facilitator.admin_edit_url : facilitator.edit_url
            : null

          return url
            ? <a href={url}>
                {facilitator.name}
              </a>
            : <span>
                {facilitator.name}
              </span>
        }
      },
      {
        title: I18n.t('models.organization.attributes.organization'),
        attribute_key: 'organization_name',
        sort_key: 'organization_name',
        render: (facilitator: Facilitator) => {
          return this.props.is_admin ?
            <a href={facilitator.admin_organization_url}>{facilitator.organization_name}</a>
            :
            <span>{facilitator.organization_name}</span>
        }
      },
      {
        title: I18n.t('models.common.type'),
        attribute_key: 'is_champion',
        sort_key: 'is_champion',
        textAlignment: 'center',
        render: (facilitator: Facilitator) => {
          return facilitator.is_champion ?
            <div><i className="far fa-trophy"></i> {I18n.t('models.facilitator.attributes.champion')}</div>
            :
            <div><i className="far fa-user"></i> {I18n.t('models.facilitator.attributes.supporting')}</div>
        }
      },
      {
        title: I18n.t('models.common.status'),
        attribute_key: 'active',
        sort_key: 'active',
        render: (facilitator: Facilitator) => {
          return facilitator.is_active
            ? <span className="badge badge-wide badge-success">
                <i className="fas fa-check"></i>
              </span>
            : <span className="badge badge-wide badge-warning">
                <i className="fas fa-clock"></i>
              </span>
        }
      }
    ]
  }

  render () {
    return <UberTable<Facilitator>
             rowDefinition={this.rowDefinition}
             searchType='facilitator'
             { ...this.props }
           />
  }
}

export default FacilitatorsTable
