import axios from 'axios';

const csrfEl = document.querySelector("meta[name='csrf-token']");

// This check is being done because Rails does not output the
// CSRF token in test mode. This was causing the JS to error
// out when running system tests.
let csrf;
if (csrfEl) {
  csrf = csrfEl.getAttribute("content");
}

const axiosInstance = axios.create({
  timeout: 5000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'X-CSRF-Token': csrf,
  },
});

export default axiosInstance;
