import * as React from 'react';
import _ from 'lodash'
import { Resource } from '../../../../types';
import TagPill from '../../../TagPill';
declare const I18n;

type Props = {
  resource: Resource,
  isAdmin: boolean,
  resourceCategoryId: number | string,
  index: number,
  handleDelete(id: number): void,
}

const ResourceListItem: React.FunctionComponent<Props> = ({
  resource,
  isAdmin,
  resourceCategoryId,
  index,
  handleDelete
}: Props): JSX.Element => {

  const formattedType = resource.resource_type == 'pdf' ? _.upperCase(resource.resource_type) : _.capitalize(resource.resource_type)

  return (
    <div className={`lesson_index__row ${!isAdmin && 'lesson_index__row--non-admin'}`} key={resource.id}>
      Type: {formattedType}
      <a
        className="lesson_index__title app-text"
        style={{ cursor: 'pointer' }}
        id={`resource_id_${resource.id}`}
        target={resource.resource_type == 'post' ? '_self' : '_blank'}
        href={resource.resource_url}
      >
        {resource.title}
      </a>
      { resource.length_estimate && (
        <span>{I18n.t('models.resource.attributes.duration')}: {resource.length_estimate}</span>
      )}
      { resource.tags &&
        resource.tags.map((tag) => {
          return (
            <TagPill
              tag={tag}
            />
          )
        })
      }
      {
        isAdmin && (
          <div className="lesson_index__icon-container">
            <a href={`/admin/explore_categories/${resourceCategoryId}/resources/${resource.id}/edit`}><i className="fal fa-edit mr-3 resource_index__icon" /></a>
            <div id={`delete_${resource.id}`} onClick={() => handleDelete(resource.id)}><i className="fal fa-trash resource_index__icon" /></div>
          </div>
        )
      }
    </div>
  );
}

export default ResourceListItem;
