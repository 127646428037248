import * as React from 'react';
import Dropzone from 'react-dropzone';
declare const I18n;

import { Course, MediaFile } from '../../../../types';

import Header from '../../../Header';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';
import NoteInput from '../../../inputs/NoteInput';
import BreadCrumb from '../../../BreadCrumb';

import api from '../../../../api';

type Props = {
  initialData: {
    maxLevel: number,
    course?: Course,
    image?: MediaFile,
  }
}

const CreateCourse: React.FunctionComponent<Props> = ({
  initialData: {
    maxLevel,
    course,
    image,
  },
}: Props): JSX.Element => {
  const [name, setName] = React.useState('');
  const [errorName, setErrorName] = React.useState('');

  const [status, setStatus] = React.useState(0);
  const [language, setLanguage] = React.useState('en');

  const [hackergalLevel, setHackergalLevel] = React.useState(null);
  const [errorHackergalLevel, setErrorHackergalLevel] = React.useState('');

  const [coverImage, setCoverImage] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (course) {
      setName(course.title);
      setStatus(course.active ? 1 : 0);
      setLanguage(course.language);
      setHackergalLevel(course.required_level);

      if (image) {
        setCoverImage([JSON.parse(image.file_data)]);
      }
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    let error = false;

    if (!(name && name.trim())) {
      setErrorName("This field can't be blank");
      setName('');
      error = true;
    }

    if (hackergalLevel === null) {
      setErrorHackergalLevel("This field can't be blank");
      error = true;
    }

    if (!error) {
      const body = {
        title: name.trim(),
        language: language,
        required_level: hackergalLevel,
        active: !!status,
      }

      let formData = new FormData();

      Object.keys(body).forEach((key) => formData.append(`course[${key}]`, body[key]))

      if (course) {
        if (coverImage) {
          if (image && image.file_data === JSON.stringify(coverImage[0])) {
            formData.append("course[cover_image]", JSON.stringify(image));
          } else {
            formData.append("course[cover_image]", coverImage[0]);
          }
        }
      } else if (coverImage) {
        formData.append("course[cover_image]", coverImage[0]);
      }

      if (course) {
        api.updateCourse(course.id, formData)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      } else {
        api.createCourse(formData)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      }
    } else {
      setLoading(false);
    }
  }

  const detectedChanges = () => {
    if (!course) return false

    const newActive = !!status;
    let sameImage = !!image;

    if (!coverImage) {
      sameImage = (image === coverImage);
    } else {
      sameImage = image ? image.file_data === JSON.stringify(coverImage[0]) : false;
    }

    if (
      newActive != course.active
      || name !== course.title
      || hackergalLevel !== course.required_level
      || language !== course.language
      || !sameImage
    ) {
      return false;
    }

    return true;
  }

  return (
    <div className="new_course">
      <Header
        title={I18n.t(course ? 'models.course.messaging.edit' : 'models.course.messaging.create')}
      />

      <BreadCrumb
        locations={[
          { title: I18n.t('models.course.attributes.course'), url: '/admin/courses' },
          { title: course ? I18n.t('models.course.messaging.edit') + ` ${course.title}` : I18n.t('models.course.messaging.create'), url: null }
        ]}
      />

      <TextInput
        columnSize={12}
        error={errorName}
        value={name}
        type="courses"
        field="name"
        label={I18n.t('models.course.attributes.name')}
        style={{ marginTop: 60, padding: 0 }}
        className="required"
        onChange={(value) => {
          setName(value);
          setErrorName('');
        }}
      />

      <div className="col-md-12">
        <div className="row">

          <SelectInput
            columnSize={4}
            value={status}
            type="courses"
            field="status"
            label={I18n.t('models.course.attributes.status')}
            style={{ paddingLeft: 0 }}
            onChange={(value) => setStatus(parseInt(value))}
          >
            <option key={0} value={0}>{I18n.t('models.course.status.inactive')}</option>
            <option key={1} value={1}>{I18n.t('models.course.status.active')}</option>
          </SelectInput>

          <SelectInput
            columnSize={4}
            value={(hackergalLevel === null) ? -2 : hackergalLevel}
            error={errorHackergalLevel}
            type="courses"
            field="required_hackergal_level"
            label={I18n.t('models.course.attributes.required_level')}
            style={{ paddingLeft: 0 }}
            className="required"
            onChange={(value) => {
              setHackergalLevel(parseInt(value));
              setErrorHackergalLevel('');
            }}
          >
            <>
              { !course && (
                <option key={`level_-2`} value={-2} style={{ display: 'none' }}></option>
              ) }
              <option key={`level_-1`} value={-1}>{ I18n.t('models.course.required_level.-1') }</option>

              {
                  Array.from(Array(maxLevel).keys()).map((x,i) => (
                    <option key={`level_${i}`} value={i}>{i === 0 ? I18n.t('models.course.required_level.0') : i}</option>
                  ))
              }
            </>
          </SelectInput>

          <SelectInput
            columnSize={4}
            value={language}
            type="courses"
            field="language"
            label={I18n.t('models.common.language')}
            style={{ paddingRight: 0 }}
            onChange={(value) => setLanguage(value)}
          >
            <option key={0} value={'en'}>{I18n.t('views.common.english')}</option>
            <option key={1} value={'fr'}>{I18n.t('views.common.french')}</option>
          </SelectInput>
        </div>
      </div>

      <div className="col-md-12 new_course__drag-container drag-drop-container">
        <div className="form-group">
          <label>{I18n.t('models.course.attributes.image')}</label>
          <div className="card">
            <div className="card-body new_course__file-container">
              {
                coverImage ? (
                  <>
                    <span className="text-left">  {coverImage && (coverImage[0].name || coverImage[0].metadata.filename)}</span>
                    <span className="fal fa-trash text-danger" data-index={0} onClick={() => setCoverImage(null)}></span>
                  </>
                ) : (
                  <Dropzone onDrop={file => setCoverImage(file)}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="new_course__section">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p className="mb-0">
                            <span className="far fa-file fa-lg mr-2 new_course__icon"></span>
                            <span className="text-underline">{I18n.t('views.forms.messaging.drag_drop_files.click_here')}</span>
                        </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )
              }
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 text-center mt-5">
        <button
          type="submit"
          name="commit"
          disabled={loading || detectedChanges()}
          className="btn btn-primary pull-right"
          onClick={handleSubmit}
        >
          {I18n.t('views.forms.submit')}
          { loading && <i className='far fa-spinner-third fa-spin btn-loader ml-2'></i> }
        </button>
      </div>
    </div>
  );
}

export default CreateCourse;
