import axios from './axios';

export const createResource = (resource_category_id, body) =>
  axios.post(`/admin/explore_categories/${resource_category_id}/resources`, body)
    .then(({ data }) => data);

export const updateResource = (resource_category_id, id, body) =>
  axios.put(`/admin/explore_categories/${resource_category_id}/resources/${id}`,body)
    .then(({ data }) => data);

export const deleteResource = (resource_category_id, id) =>
  axios.delete(`/admin/explore_categories/${resource_category_id}/resources/${id}`)
    .then(({ data }) => data);

export const deleteResourceCategory = (resource_category_id) =>
  axios.delete(`/admin/explore_categories/${resource_category_id}`)
    .then(({ data }) => data);