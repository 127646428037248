import * as React from 'react';
declare const I18n;

import { Lesson } from '../../../../types';

import Header from '../../../Header';
import BreadCrumb from '../../../BreadCrumb';

import api from '../../../../api';

type Props = {
  initialData: {
    courseId: number,
    courseTitle?: string,
    lessons: Lesson[],
    userType?: string,
  },
}

const IndexLesson: React.FunctionComponent<Props> = ({
  initialData: {
    courseId,
    courseTitle,
    lessons,
    userType,
  } }: Props): JSX.Element => {
  const isAdmin = !(userType && (userType === 'Learner' || userType === 'Facilitator'));

  const [lockedNumber, setLockedNumber] = React.useState(0);

  React.useEffect(() => {
    const lockedNumber = lessons.findIndex((lesson) => !lesson.completed);

    setLockedNumber(lockedNumber === -1 ? lessons.length : lockedNumber);
  }, []);

  const handleDelete = (id) => {
    if (confirm(I18n.t('views.forms.messaging.confirm_delete'))) {
      api.deleteLesson(courseId, id)
        .then((res) => window.location.href = res.url)
        .catch((error) => console.error('Error', error));
    }
  };

  const getIcon = (index) => {
    if (index < lockedNumber) {
      return 'fa-check-circle';
    } else if (index === lockedNumber) {
      return 'fa-play-circle';
    } else {
      return 'fa-lock';
    }
  }

  const getIconColor = (index) => {
    if (index < lockedNumber) {
      return '#5DBA19';
    } else if (index === lockedNumber) {
      return '#813091';
    } else {
      return '#505050';
    }
  }

  return (
    <div className="lesson_index">
      <Header
        title={courseTitle}
        titleButton={I18n.t('models.lesson.messaging.create')}
        locationButton={`${window.location.pathname}/new`}
        secondTitleButton="New Quiz"
        secondLocationButton={`${window.location.pathname}/new?lesson_type=quiz`}
        buttonDisabled={!isAdmin}
      />

      <BreadCrumb
        locations={[
          { title: I18n.t('models.course.attributes.courses'), url: `${isAdmin ? '/admin' : ''}/courses` },
          { title: courseTitle, url: '' },
        ]}
      />

      {
        lessons.length > 0 ? (
          lessons.map((lesson, index) => (
            <div className={`lesson_index__row ${userType && userType === 'Learner' && 'lesson_index__row--non-admin'}`} key={lesson.id}>
              {
                userType && (userType === 'Learner') && (
                  <i
                    className={`fal ${getIcon(index)} mr-4`}
                    style={{ color: getIconColor(index), fontSize: '32px' }}
                  />
                )
              }

              {
                (index > lockedNumber && userType && (userType === 'Learner')) ? (
                  <div className="lesson_index__title app-text" id={`lesson_id_${lesson.id}`}>
                    {lesson.title}
                  </div>
                ) : (
                  <a
                    className="lesson_index__title app-text"
                    style={{ cursor: 'pointer' }}
                    id={`lesson_id_${lesson.id}`}
                    href={`
                      ${isAdmin ? '/admin' : ''}/courses/${courseId}/lessons/${lesson.id}${isAdmin && lesson.lesson_type !== 'quiz' ? '/edit' : ''}
                    `}
                  >
                    {lesson.lesson_type === 'quiz' ? 'Quiz: ' : '' }{lesson.title}
                  </a>
                )
              }
              <div className="lesson_index__icon-container">
                {
                  (!userType || userType !== 'Learner') && lesson.lesson_type === 'quiz' &&
                  <a className="mr-3" href={`/admin/courses/${courseId}/lessons/${lesson.id}/stats`}>View Stats</a>
                }
                {
                  isAdmin && (
                    <>
                      <a href={`/admin/courses/${courseId}/lessons/${lesson.id}/edit`}><i className="fal fa-file-search mr-3 lesson_index__icon" /></a>
                      <div id={`delete_${lesson.id}`} onClick={() => handleDelete(lesson.id)}><i className="fal fa-trash lesson_index__icon" aria-label="Delete lesson"/></div>
                    </>
                  )
                }
              </div>
            </div>
          ))
        ) : (
          <div className="lesson_index__empty-state app-text">{I18n.t('models.lesson.messaging.empty_state')}</div>
        )
      }
    </div>
  );
}
export default IndexLesson;
