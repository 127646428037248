declare const I18n

import * as React from 'react'
import { UberTable, RowDefinition } from './UberTable'
import { Note } from '../../types'

import { parseISO, format } from 'date-fns'

type Props = {}

class NotesTable extends React.Component<Props> {
  private rowDefinition: RowDefinition<Note>[]

  constructor(props: Props) {
    super(props)

    this.rowDefinition = [
      {
        title: I18n.t('models.announcement.attributes.author'),
        attribute_key: 'author_name',
        sort_key: 'author_name'
      },
      {
        title: I18n.t('models.resource.attributes.date'),
        attribute_key: 'created_at',
        sort_key: 'created_at',
        render: (note: Note) => {
          let date = parseISO(note.created_at)
          return format(date, 'MM/dd/yyyy @ h:mmaa')
        }

      },
      {
        title: 'Note',
        attribute_key: 'body'
      }
    ]
  }

  render() {
    return <UberTable<Note>
      rowDefinition={this.rowDefinition}
      searchType='note'
      {...this.props}
    />
  }
}

export default NotesTable
