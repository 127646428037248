import * as React from 'react';
declare const I18n;

import { Course, MediaFile } from '../../../../types';

import ListItem from '../../components/list_item';
import Header from '../../../Header';

import api from '../../../../api';

type Props = {
  initialData: {
    courses: Course[],
    image: MediaFile,
    userType?: string,
    userLevel?: number,
  }
}

const IndexCourse: React.FunctionComponent<Props> = ({
  initialData: {
    courses,
    image,
    userType,
    userLevel,
  } }: Props): JSX.Element => {
  const [courseList, setCourseList] = React.useState(courses);

  const handleDelete = (id) => {
    if (confirm(I18n.t('views.forms.messaging.confirm_delete'))) {
      api.deleteCourse(id)
        .then((res) => {
          setCourseList(courseList.filter((item) => item.id !== id));
          window.location.href = res.url;
        })
        .catch((error) => console.error('Error', error));
    }
  }

  return (
    <div className="courses_index">
      <Header
        title={I18n.t('models.course.attributes.courses')}
        titleButton={I18n.t('models.course.messaging.create')}
        locationButton="/admin/courses/new"
        buttonDisabled={userType && (userType === 'Learner' || userType === 'Facilitator')}
      />

      <div className="courses_index__list">
      {
        courseList.length > 0 ? (
          <>
            {
              courseList.map((row, index) => (
                <ListItem
                  title={row.title}
                  requiredLevel={row.required_level}
                  id={row.id}
                  index={index}
                  key={row.id}
                  image={row.cover_image_data ? row.file_url : null}
                  handleDelete={handleDelete}
                  isFacilitator={userType && (userType === 'Facilitator')}
                  isLearner={userType && (userType === 'Learner')}
                  learnerLevel={userLevel || 0}
                  completed={row.completed}
                />
              ))
            }
          </>
        ) : (
          <div className="courses_index__empty-state app-text">{I18n.t('models.course.messaging.empty_state')}</div>
        )
      }
      </div>
    </div>
  );
}

export default IndexCourse;
