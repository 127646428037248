declare const I18n
declare const axios
axios.defaults.timeout = 10000;

import * as React from 'react'

import { Tag, Resource, ResourceCategory } from '../../types'
import { PaginationMetadata } from '../tables/UberTable'
import CategoryTile from './CategoryTile'
import ResourceTile from './ResourceTile'

const debounce = require('lodash/debounce')
const qs = require('qs')

type Props = {
  categories: ResourceCategory[]
  show_new_cta: boolean,
  for_learners: boolean,
}

type State = {
  resources: { [key: string]: Resource[] }
  metadata: PaginationMetadata
  filterQuery: string
  selectedCategory: null | ResourceCategory
  sortKey: string
  sortDirection: string
  tags: Tag[]
  selectedTag: null | Tag
  selectedLevel: string
  loading: boolean
  error: boolean
  showNewCta: boolean
}

class ResourcesGrid extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      resources: {},
      metadata: {
        items: 0,
        pages: 0,
        page: 1
      },
      filterQuery: '',

      selectedCategory: null,
      selectedTag: null,
      selectedLevel: '',

      sortKey: 'title',
      sortDirection: 'asc',

      tags: [],

      showNewCta: this.props.show_new_cta,
      loading: false,
      error: false
    }

    let newState = this.stateFromAnchor()
    this.state = { ...this.state, ...newState }

    window.addEventListener('popstate', this.handlePopState.bind(this))
  }

  handlePopState () {
    const anchorState = this.stateFromAnchor()

    if (window.location.hash.startsWith('#grid')) {
      this.setState(anchorState, () => this.fetchPage())
    } else {
      this.setState({
        selectedCategory: null,
        selectedTag: null,
        selectedLevel: ''
      })
    }
  }

  componentDidMount () {
    if (this.state.selectedCategory) {
      this.fetchPage()
    }
  }

  stateToAnchor () {
    const anchorState = {
      page: this.state.metadata.page,
      query: this.state.filterQuery,
      category: this.state.selectedCategory?.id,
      level: this.state.selectedLevel
    }

    let anchorString = 'grid&' + Object.entries(anchorState).map(([key, value]) => {
      if (value && value !== '') {
        return `${key}=${value}`
      }
    }).filter(Boolean).join('&')

    window.location.hash = anchorString
  }

  stateFromAnchor () {
    if (window.location.hash.length === 0) return

    let parsedAnchor = window.location.hash.split('&').map((e) => e.split('='))
    const anchorState = parsedAnchor.reduce((obj, [k, v]) => (obj[k] = v, obj), {}) as any

    let newState = { metadata: this.state.metadata }
    newState['metadata']['page'] = anchorState.page
    newState['filterQuery'] = anchorState.filter || false
    newState['selectedCategory'] = anchorState.category ? this.props.categories.find((c) => c.id === parseInt(anchorState.category, 10)) : false
    newState['selectedLevel'] = anchorState.level || ''

    return newState
  }

  async fetchPage (page?: number) {
    this.setState({
      loading: true
    })

    let pageNumber = page || this.state.metadata.page

    let params = {
      type: 'resource',
      page: pageNumber,
      conditions: {}
    } as any

    if (this.state.filterQuery) {
      params.query = this.state.filterQuery
    }

    if (this.state.selectedCategory) {
      params.conditions.resource_category_id = this.state.selectedCategory.id
    }

    if (this.state.selectedLevel !== '') {
      params.conditions.hackergal_level = this.state.selectedLevel
    }

    if (this.state.sortKey) {
      params.order_by = this.state.sortKey
      params.order_dir = this.state.sortDirection
    }

    if (this.state.filterQuery) {
      params.query = this.state.filterQuery
    }

    if (this.state.selectedTag) {
      params.tags = this.state.selectedTag.name
    }

    let queryParams = qs.stringify(params, { encode: false });
    let url = `/search?${queryParams}`

    try {
      const request = await axios.get(url)
      const response = request.data

      let newResources = {}
      if (this.state.selectedCategory) {
        newResources[this.state.selectedCategory.id] = response.results
      }

      this.setState({
        resources: { ...this.state.resources, ...newResources },
        metadata: response.metadata
      }, () => {
        this.stateToAnchor()

        if (this.state.selectedCategory.id) {
          const tagsHash = this.state.resources[this.state.selectedCategory.id].flatMap(result => result.tags).reduce((obj, tag) => {
            obj[tag.id] = tag
            return obj
          }, {})
          const tags = Object.values(tagsHash) as Tag[]

          this.setState({ tags })
        }
      })

    } catch (err) {
      this.setState({ error: true })
      console.log(`ResourceGrid fetchPage: `, err)
    }
  }

  selectCategory (id: number | null) {
    this.setState({
      tags: [],
      selectedTag: null,
      selectedLevel: id ? this.state.selectedLevel : '',
      selectedCategory: this.props.categories.find((category) => category.id === id)
    }, async () => {
      this.stateToAnchor()
    })
  }

  // selectTag (id: number) {
  //   this.setState({
  //     selectedTag: this.state.tags.find((tag) => tag.id === id)
  //   }, () => {
  //     this.stateToAnchor()
  //   })
  // }

  selectLevel (id: string) {
    let levelToSet
    if (id === '') {
      levelToSet = ''
    } else {
      levelToSet = parseInt(id, 10)
    }

    this.setState(
      {
        selectedLevel: levelToSet
      }, () => {
        this.stateToAnchor()
      })
  }

  selectResource (resource: Resource) {
    if (resource.resource_type == "pdf" || resource.resource_type == 'link') {
      var win = window.open(resource.resource_url, '_blank');
      win.focus();
    } else {
      window.location.href = resource.resource_url
    }
  }

  renderCategories () {
    return (
      <div className='resources-grid'>
        {
          this.props.categories.length === 0
            ? this.emptyCategoryState()
            : this.props.categories.map((category) => <CategoryTile category={category} key={category.id} onClick={() => this.selectCategory(category.id)}/> )
        }
      </div>
    )
  }

  showResource (resource) {
    return resource.permissions.view
  }

  renderResources () {
    return (
      <div className='resources-grid'>
        {
          this.state.resources[this.state.selectedCategory.id]
          ?
            (
              // FIX ME: this should be updated to check if the length of resources visible to the user type / level is 0 instead of just the length in general.
              // otherwise the empty state will never show
              this.state.resources[this.state.selectedCategory.id].length === 0
                ? this.emptyResourcesState()
                : this.state.resources[this.state.selectedCategory.id].map((resource) =>
                    this.showResource(resource)
                      ? <ResourceTile resource={resource} resourceCategory={this.state.selectedCategory} key={resource.id} onClick={() => this.selectResource(resource)} />
                      : ''
                  )
            )
          :
          this.loadingState()
        }
      </div>
    )
  }

  emptyResourcesState () {
    return (
      <div className="resource-grid-empty">
        <div className="resource-grid-empty-title">{I18n.t('models.resource.messaging.empty_state')}</div>
      </div>
    )
  }

  emptyCategoryState () {
    return (
      <div className="resource-grid-empty">
        <div className="resource-grid-empty-title">{I18n.t('models.resource.messaging.no_categories')}</div>
      </div>
    )
  }

  loadingState() {
    return (
      <div className="resource-grid-empty">
        <div className="resource-grid-empty-title">{I18n.t('views.common.loading')}</div>
      </div>
    )
  }

  render () {
    return (
      <div className='resources-container'>
        { this.state.showNewCta &&
          <div className="cta float-right">
            <a href={`/admin/resources/new${this.state.selectedCategory ? `?category_id=${this.state.selectedCategory.id}` : ''}`} className="btn btn-primary">{I18n.t('models.resource.messaging.create')}</a>
          </div>
        }
        {
          this.state.selectedCategory
          ?
            <h2 className="section-heading">
              {I18n.t('models.resource.attributes.category')}: {this.state.selectedCategory.name}
              <div className='circle-icon cursor-pointer' onClick={() => { this.selectCategory(null) }}>
                <i className='fas fa-times'></i>
              </div>
            </h2>
          :
          <h2 className="section-heading">{ I18n.t('models.resource.messaging.categories') }</h2>
        }

        <div className='resources-filters form-inline'>
          {/* {
            this.state.tags.length > 0
            &&
            <div className='resources-filter'>
              <label htmlFor='tag_id' className='mr-2'>{I18n.t('models.tag.tags')}</label>
              <select id='tag_id' className='custom-select' name='tag_id' value={this.state.selectedTag?.id} onChange={(e) => this.selectTag(parseInt(e.target.value, 10))}>
                <option value=''></option>
                {
                  this.state.tags.map((tag) => {
                    return <option value={`${tag.id}`} key={`${tag.id}`}>{tag.name}</option>
                  })
                }
              </select>
            </div>
          } */}

          {
            this.state.selectedCategory &&
            <div className='resources-filter'>
              <label htmlFor='level_id' className='mr-2'>{I18n.t('models.resource.attributes.level')}</label>
              <select id='level_id' className='custom-select' name='level_id' value={this.state.selectedLevel} onChange={(e) => this.selectLevel(e.target.value)}>
                <option value=''>{I18n.t('models.resource.levels.all')}</option>
                <option value='0'>{I18n.t('models.resource.levels.launchpad')}</option>
                <option value='1'>{I18n.t('models.resource.levels.level_1')}</option>
                <option value='2'>{I18n.t('models.resource.levels.level_2')}</option>
                <option value='3'>{I18n.t('models.resource.levels.level_3')}</option>
                <option value='4'>{I18n.t('models.resource.levels.level_4')}</option>
              </select>
            </div>
          }
        </div>

        {
          this.state.selectedCategory
          ?
            this.renderResources()
          :
            this.renderCategories()
        }
      </div>
    )
  }
}

export default ResourcesGrid