declare const I18n

import * as React from 'react'
import { UberTable, RowDefinition } from './UberTable'
import { Organization } from '../../types'
import { parseISO, format } from 'date-fns'

type Props = { }

class OrganizationsTable extends React.Component<Props> {
  private rowDefinition: RowDefinition<Organization>[]

  constructor(props: Props) {
    super(props)

    this.rowDefinition = [
      {
        title: I18n.t('models.common.name'),
        attribute_key: 'name',
        sort_key: 'name',
        render: (organization: Organization) => {
          return <a href={organization.edit_url}>{organization.name}</a>
        }
      },
      {
        title: I18n.t('models.common.location'),
        attribute_key: 'city',
        sort_key: 'city',
        render: (organization: Organization) => {
          return `${organization.city}, ${organization.province.full_name}`
        }
      },
      {
        title: I18n.t('models.learner.attributes.learners'),
        attribute_key: 'learners_count',
        sort_key: 'learners_count',
        textAlignment: 'center'
      },
      {
        title: I18n.t('views.common.type'),
        attribute_key: 'organization_type',
        sort_key: 'organization_type',
        textAlignment: 'center',
        render: (organization: Organization) => {
          return I18n.t('models.organization.organization_types.' + `${organization.organization_type}`)
        }
      },
      // {
      //   title: I18n.t('models.common.language'),
      //   attribute_key: 'language',
      //   sort_key: 'language',
      //   textAlignment: 'center',
      //   render: (organization: Organization) => {
      //     return organization.language == 'en' ? I18n.t('views.common.english') : I18n.t('views.common.french')
      //   }
      // },
      {
        title: I18n.t('models.organization.attributes.registration_date'),
        attribute_key: 'created_at',
        sort_key: 'created_at',
        render: (organization: Organization) => {
          let date = parseISO(organization.created_at)
          return format(date, 'MM.dd.yyyy')
        }
      }
    ]
  }

  render() {
    return <UberTable<Organization>
      rowDefinition={this.rowDefinition}
      searchType='organization'
      showTags={true}
      {...this.props}
    />
  }
}

export default OrganizationsTable
