import Tab from 'bootstrap/js/dist/tab';

document.addEventListener("DOMContentLoaded", function () {
  const anchor = window.location.hash

  // Display requested tab from the URL
  if (anchor.startsWith('#tab-')) {
    const tabName = anchor.split('#tab-')[1]
    if ($(`#${tabName}-tab`).length > 0) {
      let tab = new Tab($(`#${tabName}-tab`)[0])
      tab.show()
    }
  }

  $('#hackergal-tabs li a').on('click', function (e) {
    const tabName = e.target.id.split('-')[0]
    window.location.hash = `tab-${tabName}`
  })
})
