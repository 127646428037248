let otherValueInputs = document.querySelectorAll('input[value="other"], option[value="other"]');
let hubReferralSocialMediaOption = document.querySelector('input[id*="hub_referral_social_media"]');

if (otherValueInputs) {
  otherValueInputs.forEach((input) => {
    let inputType = input.tagName;
    if (inputType == 'INPUT') {
      input.addEventListener('change', (event) => {
        inputType = event.target.getAttribute('type');
        let otherInputContainer = event.target.parentElement.parentElement.querySelector('.other-option');

        if (inputType == 'radio') {
          let siblingInputs = event.target.parentElement.parentElement.querySelectorAll('input[type="radio"]');

          if (siblingInputs) {
            siblingInputs.forEach((siblingInput) => {
              if (siblingInput != event.target) {
                siblingInput.addEventListener('change', (event) => {
                  if (otherInputContainer) {
                    otherInputContainer.style.display = 'none';
                  }
                });
              }
            });
          }
        }

        if (otherInputContainer) {
          otherInputContainer.style.display = event.target.checked ? 'block' : 'none';
          let otherInput = otherInputContainer.querySelector('input');

          if (otherInput && event.target.checked) {
            otherInput.focus();
            if (otherInput.disabled) {
              otherInput.removeAttribute('disabled')
            }
          }
        }
      });
    } else if (inputType == 'OPTION') {
      input.parentElement.addEventListener('change', (event) => {
        let otherInputContainer = event.target.parentElement.parentElement.querySelector('.other-option');

        if (otherInputContainer) {
          otherInputContainer.style.display = event.target.value == 'other' ? 'block' : 'none';
          let otherInput = otherInputContainer.querySelector('input');

          if (otherInput && event.target.value == 'other') {
            otherInput.focus();
            if (otherInput.disabled) {
              otherInput.removeAttribute('disabled')
            }
          }
        }
      });
    }
  });
}

if (hubReferralSocialMediaOption) {
  hubReferralSocialMediaOption.addEventListener('change', (event) => {
    let socialMediaTextContainer = event.target.parentElement.parentElement.querySelector('.social-media-option');
    let siblingInputs = event.target.parentElement.parentElement.querySelectorAll('input[type="radio"]');

    if (siblingInputs) {
      siblingInputs.forEach((siblingInput) => {
        if (siblingInput != event.target) {
          siblingInput.addEventListener('change', (event) => {
            if (socialMediaTextContainer) {
              socialMediaTextContainer.style.display = 'none';
            }
          });
        }
      });
    }

    if (socialMediaTextContainer) {
      socialMediaTextContainer.style.display = event.target.checked ? 'block' : 'none';
      let socialMediaInput = socialMediaTextContainer.querySelector('input');

      if (socialMediaInput && event.target.checked) {
        socialMediaInput.focus();
        if (socialMediaInput.disabled) {
          socialMediaInput.removeAttribute('disabled')
        }
      }
    }
  });
}
