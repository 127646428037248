import * as React from 'react';
declare const I18n;
import { Collapse } from 'react-collapse';
import DOMPurify from 'dompurify/dist/purify.min';

import { Lesson } from '../../../../types';

import Filter from 'bad-words';
import Header from '../../../Header';
import BreadCrumb from '../../../BreadCrumb';
import Section from '../../../Section';
import Question from '../../../Question';

import api from '../../../../api';

type Props = {
  initialData: {
    lesson: Lesson,
    courseId: number,
    courseLevel: number,
    isFacilitator: boolean
  },
}

const ShowLesson: React.FunctionComponent<Props> = ({ initialData: { lesson, courseId, courseLevel, isFacilitator } }: Props): JSX.Element => {
  const [sectionsArray, setSectionsArray] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [badWordsError, setBadWordsError] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [filter] = React.useState(new Filter());
  const DOMPurifyConfig = {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['target']
  };

  filter.removeWords('hui');

  React.useEffect(() => {
    const sectionArray = lesson.sections.map((section) => {
      const questions = section.questions.map((question) => {
        const hasValue = question.submission.length > 0;

        if (!isEdit && hasValue) {
          setIsEdit(true);
        }

        return ({
          value: hasValue ? question.submission[0].answer : '',
          error: '',
          id: hasValue ? question.submission[0].id : '',
        })}
      );

      return {
        isOpen: false,
        questions,
      };
    });

    setSectionsArray(sectionArray);
  }, []);

  const handleSubmit = () => {
    let error = false

    sectionsArray.forEach((section, index) => {
      section.questions.forEach((input, ind) => {
        if (!(input.value && input.value.trim() && (input.value.trim().length > 20))) {
          const newArray = sectionsArray;

          newArray[index].questions[ind].error = I18n.t('models.lesson.messaging.submission_length');

          setSectionsArray([...newArray]);
          section.isOpen = true;
          error = true
        } else {
          section.isOpen = false;
        }
      });
    });

    if (!error) {
      const submissions = [];

      lesson.sections.forEach((section, index) => {
        section.questions.forEach((question, ind) => {
          submissions.push({
            section_id: section.id,
            question_id: question.id,
            answer: sectionsArray[index].questions[ind].value,
            id: question.submission.length > 0 ? question.submission[0].id : ''
          })
        })
      })

      const body = {
        course_level: courseLevel,
        submissions,
      };

      if (isEdit) {
        api.updateSubmitLesson(courseId, lesson.id, body)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      } else {
        api.submitLesson(courseId, lesson.id, body)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      }
    } else {
      document.getElementById('section_1').scrollIntoView();
      setLoading(false);
    }
  }

  return (
    <div className="lessons_show">
      <Header title={lesson.title}/>

      <BreadCrumb
        locations={[
          { title: I18n.t('models.course.attributes.courses'), url: '/courses' },
          { title: lesson.course_title, url: `/courses/${courseId}/lessons` },
          { title: lesson.title, url: '' },
        ]}
      />

      <div className="lessons_show__body">
        <Section
          title={I18n.t('views.common.overview')}
        />

        <div
          className="section__body app-text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lesson.overview, DOMPurifyConfig) }}
        />

        {
          lesson.sections.map((section, index) => {
            return (
              <div
                className="lessons_show__section"
                key={section.id}
              >
                <Section
                  title={section.title}
                  withInput={section.questions.length > 0}
                  withBorder
                  index={index+1}
                  iconId={section.id}
                  onArrowPress={() => {
                    const newArray = sectionsArray;

                    newArray[index].isOpen = !newArray[index].isOpen;

                    setSectionsArray([...newArray]);
                  }}
                />

                <Collapse isOpened={sectionsArray[index] && sectionsArray[index].isOpen}>
                  <div
                    className="section__body app-text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content, DOMPurifyConfig) }}
                  />

                  {
                    section.questions.map((question, ind) => {
                      return (
                        <div>
                          <Question
                          key={`${index}_${ind}`}
                          title={question.title}
                          value={sectionsArray[index] && sectionsArray[index].questions[ind].value}
                          error={sectionsArray[index] && sectionsArray[index].questions[ind].error}
                          inputId={`question_input_${question.id}`}
                          onBlur={(value) => {
                            if (filter.isProfane(value)) {
                              const newArray = sectionsArray;

                              newArray[index].questions[ind].error = I18n.t('models.lesson.messaging.bad_language');

                              setSectionsArray([...newArray]);
                              setBadWordsError(true);
                            }
                          }}
                          isEdit={isEdit}
                          isFacilitator={isFacilitator}
                          onChange={(value) => {
                            const newArray = sectionsArray;

                            newArray[index].questions[ind].value = value;
                            newArray[index].questions[ind].error = '';
                            setSectionsArray([...newArray]);
                            setBadWordsError(false);
                          }}
                        />
                        { isEdit &&
                          <small>{I18n.t('models.lesson.messaging.no_edit')}</small>
                        }
                        </div>
                      )
                    })
                  }
                </Collapse>
              </div>
            )}
          )
        }

        <div
          className="col-12 text-center"
          style={{ paddingTop: '40px' }}
        >
          {
            isFacilitator ? (
              <a href={`/courses/${courseId}/lessons`}>
                <button
                  disabled={loading}
                  className="btn btn-primary pull-right"
                >
                  {I18n.t('models.lesson.messaging.back')}
                  { loading && <i className='far fa-spinner-third fa-spin btn-loader ml-2'></i> }
                </button>
              </a>
            ) : (
              <button
                type="submit"
                name="commit"
                disabled={loading || badWordsError || isEdit}
                className="btn btn-primary pull-right"
                onClick={handleSubmit}
              >
                {I18n.t('models.lesson.messaging.show')}
                { loading && <i className='far fa-spinner-third fa-spin btn-loader ml-2'></i> }
              </button>
            )}
        </div>
      </div>
    </div>
  );
}

export default ShowLesson;
