import * as React from 'react';
import _ from 'lodash'
import { Resource } from '../../../../types';
import TagPill from '../../../TagPill';
declare const I18n;

type Props = {
  resource: Resource,
  isAdmin: boolean,
  resourceCategoryId: number | string,
  index: number,
  handleDelete(id: number): void,
}

const ResourceListItem: React.FunctionComponent<Props> = ({
  resource,
  isAdmin,
  resourceCategoryId,
  index,
  handleDelete
}: Props): JSX.Element => {
  const mod = index % 3;

  const formattedType = resource.resource_type == 'pdf' ? _.upperCase(resource.resource_type) : _.capitalize(resource.resource_type)

  const imageNode = (
    <div className={`card-list-item__image-container`}>
      {
        resource.cover_image_url ? (
          <div className="card-list-item__image card-list-item__image--background" style={{ backgroundImage: `url(${resource.cover_image_url})` }} />
        ) : (
          <div className={`card-list-item__image card-list-item__image--default`} />
        )
      }
    </div>
  );

  const resourceTypeData = () => {
    if (resource.resource_type === 'pdf') {
      return {
        icon: 'far fa-file-pdf'
      }
    } else if (resource.resource_type === 'link') {
      return {
        icon: 'far fa-link'
      }
    } else if (resource.resource_type === 'post') {
      return {
        icon: 'far fa-line-columns'
      }
    }
  }


  return (
    <div
      className="card-list-item"
      style={{
        marginRight: (mod === 0 || mod === 1) ? '1.2%' : 0,
        marginLeft: (mod === 1 || mod === 2) ? '1.2%' : 0
      }}
    >
      <a
        id={`resource_id_${resource.id}`}
        style={{ cursor: 'pointer' }}
        href={resource.resource_url}
        target={resource.resource_type == 'post' ? '_self' : '_blank'}
      >
        {imageNode}
      </a>

      <div className={`card-list-item__card-type-container card-list-item__card-type--${formattedType}`}>
        <i className={`far ${resourceTypeData()['icon']} fa-1x card-list-item__icon`} />
        <div className="card-list-item__level">
          { I18n.t(`models.resource.types.${resource.resource_type}`)}
        </div>
      </div>

      <div className="card-list-item__meta-container">
        <div className="card-list-item__title-container">
          <div id={`resource_title_${resource.id}`} className="card-list-item__title">
            {resource.title}
          </div>
            {
              isAdmin && (
                <div className="card-list-item__admin-container">
                <a href={`/admin/explore_categories/${resourceCategoryId}/resources/${resource.id}/edit`}><i className="fal fa-edit card-list-item_icon--admin" /></a>
                <div id={`delete_${resource.id}`} onClick={() => handleDelete(resource.id)}><i className="fal fa-trash card-list-item__icon--admin" /></div>
                </div>
              )
            }
        </div>

        {
          resource.length_estimate && (
            <div className="card-list-item__duration-container">
              <i className="far fa-clock card-list-item__duration--icon" />
              <span>{I18n.t('models.resource.attributes.duration')}: {resource.length_estimate}</span>
            </div>
          )
        }

        {
          resource.tags && (
            <div className="card-list-item__tag-container">
            {
              resource.tags.map((tag) => {
                return (
                  <div className="card-list-item__tag--item">
                    { tag.name }
                  </div>
                )
              })
            }
            </div>
          )
        }
      </div>
    </div>

  );
}

export default ResourceListItem;
