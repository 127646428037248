declare const I18n

import * as React from 'react'
import { parseISO, format } from 'date-fns'
import { Resource, ResourceCategory } from '../../types'

type Props = {
  resource: Resource,
  resourceCategory: ResourceCategory,
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

class ResourceTile extends React.Component<Props, {}> {

  date () {
    let date = parseISO(this.props.resource.updated_at)
    return format(date, 'MM.dd.yyyy')
  }

  htmlMeta () {
    if (this.props.resource.resource_type === 'pdf') {
      return {
        wrapper: 'resource-card-pdf',
        name: I18n.t('models.resource.types.pdf'),
        icon: 'far fa-file-pdf'
      }
    } else if (this.props.resource.resource_type === 'link') {
      return {
        wrapper: 'resource-card-link',
        name: I18n.t('models.resource.types.link'),
        icon: 'far fa-link'
      }
    } else if (this.props.resource.resource_type === 'youtube') {
      return {
        wrapper: 'resource-card-video',
        name: I18n.t('models.resource.types.link'),
        icon: 'far fa-video'
      }
    } else if (this.props.resource.resource_type === 'post') {
      return {
        wrapper: 'resource-card-post',
        name: I18n.t('models.resource.types.post'),
        icon: 'far fa-line-columns'
      }
    }
  }

  coverImage () {
    if (this.props.resource.hackergal_level === 0) {
      return '/resources/launchpad_cover.png'
    } else if (this.props.resource.hackergal_level === 1) {
      return '/resources/level_1_cover.png'
    } else if (this.props.resource.hackergal_level === 2) {
      return '/resources/level_2_cover.png'
    } else if (this.props.resource.hackergal_level === 3) {
      return '/resources/level_3_cover.png'
    } else if (this.props.resource.hackergal_level === 4) {
      return '/resources/level_4_cover.png'
    } else {
      return '/resources/all_levels.png'
    }
  }

  canEdit () {
    return this.props.resource.permissions && this.props.resource.permissions.edit && this.props.resource.edit_url
  }

  canDelete () {
    return this.props.resource.permissions && this.props.resource.permissions.delete && this.props.resource.delete_url
  }

  clickEdit (e) {
    e.stopPropagation()
    window.location.href = this.props.resource.edit_url
  }

  hasTargetAndLevel () {
    return Boolean(this.props.resourceCategory.target_role && this.props.resource.hackergal_level_label)
  }

  formattedRole () {
    return this.props.resourceCategory.target_role.charAt(0).toUpperCase() + this.props.resourceCategory.target_role.slice(1);
  }

  render () {
    return (
      <div className={`resource-card cursor-pointer ${this.htmlMeta().wrapper}`} onClick={this.props.onClick}>
        <div className='resource-card-image' style={ {backgroundImage: `url('${this.coverImage()}')`} }>
            {
                this.canEdit()
                &&
                <div className="resource-card-target">
                    { this.formattedRole() }
                    {
                        this.hasTargetAndLevel() &&
                        <span> - </span>
                    }
                    { this.props.resource.hackergal_level_label }
                </div>
            }
        </div>

        <div className='resource-card-content'>

          <div className='resource-card-title'>
            <div className='resource-card-title-content'>

              <div className='resource-card-type'>
                <i className={`resource-card-type-icon ${this.htmlMeta().icon}`}></i>
                <span>{this.htmlMeta().name }</span>
              </div>

              {this.props.resource.title}
            </div>
          </div>

          <div className='resource-card-meta'>
            <span className='resource-card-meta-text'>{ this.date() }</span>
            <span className='resource-card-actions'>
              {
                this.canEdit()
                &&
                <a>
                  <i className="fal fa-edit" title="Edit" onClick={this.clickEdit.bind(this)} ></i>
                </a>
              }
              &nbsp;
              {
                this.canDelete()
                &&
                <a data-confirm={I18n.t('views.forms.messaging.confirm_delete')} rel="nofollow" data-method="delete" href={this.props.resource.delete_url}>
                  <i className="fal fa-trash-alt" title="Delete" aria-hidden="true"></i>
                </a>
              }
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default ResourceTile
