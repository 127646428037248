import * as React from 'react';
declare const I18n;

import Wyswiyg from './Wysiwyg'

type Props = {
  value: string,
  error: string,
  type: string,
  field: string,
  label: string,
  className?: string,
  style?: object,
  toolbarOptions: object,
  required: boolean,
  onChange(value: string): void,
}

const NoteInput: React.FunctionComponent<Props> = ({
  value,
  error,
  type,
  field,
  label,
  className,
  style,
  toolbarOptions,
  required,
  onChange,
}: Props): JSX.Element => (
  <div
    id={`${type}_${field}_wysiwyg`}
    className="col-md-12"
    style={{ padding: 0, marginTop: '30px' }}
  >
    <div className="form-group">
      <Wyswiyg
        inputId={`${type}_${field}`}
        inputName={field.includes('_') ? `${type}[${field.replace('_', '][')}]` : `${type}[${field}]`}
        initialValue={value}
        required={required}
        labelText={label}
        errors={error || null}
        onTextChange={(text) => onChange(text)}
      />
    </div>
  </div>
);

export default NoteInput;
