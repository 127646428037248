import { url } from 'inspector';
import * as React from 'react';
declare const I18n;

type Props = {
  title: string,
  requiredLevel: number,
  index: number,
  id: number,
  image?: string,
  isFacilitator?: boolean,
  isLearner?: boolean,
  completed?: boolean,
  learnerLevel?: number,
  handleDelete(id: number): void,
}

const ListItem: React.FunctionComponent<Props> = ({
  title,
  requiredLevel,
  index,
  id,
  handleDelete,
  image,
  isFacilitator,
  isLearner,
  learnerLevel,
  completed,
}: Props): JSX.Element => {
  const mod = index % 3;
  let overlay = null;
  let levelText = `${I18n.t('models.course.required_level.level')} ${requiredLevel}`;

  const isAdmin = !(isLearner || isFacilitator);

  if (isLearner) {
    if (requiredLevel < learnerLevel) {
      if (completed) {
        overlay = 'card-list-item__image--completed';
      }
    } else if (requiredLevel > learnerLevel) {
      overlay = 'card-list-item__image--locked';
    }
  }

  if (requiredLevel === 0) {
    levelText = I18n.t('models.course.required_level.0');
  } else if (requiredLevel === -1) {
    levelText = I18n.t('models.course.required_level.-1');
  }

  const imageNode = (
    <div className={`card-list-item__image-container ${overlay}`}>
      {
        image ? (
          <div className="card-list-item__image card-list-item__image--background" style={{ backgroundImage: `url(${image})` }} />
        ) : (
          <div className={`card-list-item__image card-list-item__image--default ${overlay}`} />
        )
      }

      {
        overlay && (
          <div className="card-list-item__overlay">
            <i
              className={`fal ${overlay.includes('completed') ? 'fa-check-circle' : 'fa-lock'} card-list-item__overlay-icon`}
              style={{ color: overlay.includes('completed') ? '#8DC862' : '#A41461' }}
            />
          </div>
        )
      }
    </div>
  );

  return (
    <div
      className="card-list-item"
      style={{
        marginRight: (mod === 0 || mod === 1) ? '1.2%' : 0,
        marginLeft: (mod === 1 || mod === 2) ? '1.2%' : 0
      }}
    >
      {
        overlay === 'card-list-item__image--locked' ? imageNode : (
          <a
            style={{ cursor: 'pointer' }}
            href={`${isAdmin ? '/admin' : ''}/courses/${id}/lessons`}
          >
            {imageNode}
          </a>
        )
      }

        <div className="card-list-item__card-type-container">
          <i className="fal fa-book-open fa-1x card-list-item__icon" />
          <div className="card-list-item__level">
            {levelText}
          </div>
        </div>

        <div className="card-list-item__meta-container">
          <div className="card-list-item__title-container">
            <div id={`courses_title_${id}`} className="card-list-item__title">{title}</div>
              {
                isAdmin && (
                  <div style={{ display: 'flex' }}>
                    <a href={`/admin/courses/${id}/edit`}><i className="fal fa-pencil fa-lg card-list-item_icon--title" /></a>
                    <div id={`delete_${id}`} onClick={() => handleDelete(id)}><i className="fal fa-trash fa-lg card-list-item__icon--title" /></div>
                  </div>
                )
              }
          </div>
        </div>
    </div>
  );
}

export default ListItem;
