import axios from './axios';

export const createCourse = (body) =>
  axios.post('/admin/courses', body)
    .then(({ data }) => data);

export const updateCourse = (id, body) =>
  axios.put(`/admin/courses/${id}`,body)
    .then(({ data }) => data);

export const deleteCourse = (id) =>
  axios.delete(`/admin/courses/${id}`)
    .then(({ data }) => data);
