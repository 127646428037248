import * as React from 'react';
import Dropzone from 'react-dropzone';
declare const I18n;

import { Resource, MediaFile } from '../../../../types';

import Header from '../../../Header';
import BreadCrumb from '../../../BreadCrumb';
import TextInput from '../../../inputs/TextInput';
import SelectInput from '../../../inputs/SelectInput';
import NoteInput from '../../../inputs/NoteInput';
import SingleFileUpload from '../../../forms/SingleFileUpload';
import TagListInput from '../../../../components/inputs/TagInput'

import api from '../../../../api';

type Props = {
  initialData: {
    resource?: Resource,
    resourceCategoryId: number,
    resourceCategoryTitle?: string,
    tileImage: MediaFile,
    attachmentFile: MediaFile
  }
}

const CreateResource: React.FunctionComponent<Props> = ({
  initialData: {
    resource,
    resourceCategoryId,
    resourceCategoryTitle,
    tileImage,
    attachmentFile
  },
}: Props): JSX.Element => {
  const createSection = (section = null) => {
    // if it has a section then it is an update
    const newSection = ({
      title: section ? section.title : '',
      errorTitle: '',
      content: section ? section.content : '',
      errorContent: '',
      id: section ? section.id : null,
    });

    return newSection;
  };

  const [title, setTitle] = React.useState('');
  const [errorTitle, setErrorTitle] = React.useState('');

  const [lengthEstimate, setLengthEstimate] = React.useState('');

  const [resourceType, setResourceType] = React.useState('');

  const [resourceLink, setResourceLink] = React.useState('');
  const [errorResourceLink, setErrorResourceLink] = React.useState('');

  const [resourceFile, setResourceFile] = React.useState(null);
  const [errorResourceFile, setErrorResourceFile] = React.useState('');

  const [resourceBody, setResourceBody] = React.useState('');
  const [errorResourceBody, setErrorResourceBody] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const [sections, setSections] = React.useState([]);

  const [coverImage, setCoverImage] = React.useState(null);

  const [resourceTags, setResourceTags] = React.useState(null);

  React.useEffect(() => {
    // if it has a resource then it is an update
    if (resource) {
      let rst = 'upload'
      if (resource.resource_type == 'pdf') {
        rst = 'upload'
      } else if (resource.resource_type) {
        rst = resource.resource_type
      }

      setResourceType(rst);
      setTitle(resource.title);
      setResourceBody(resource.body);
      setLengthEstimate(resource.length_estimate);


      if (rst == 'link') {
        setResourceLink(resource.resource_url);
      }

      if (tileImage) {
        setCoverImage(tileImage);
      }

      if (attachmentFile) {
        setResourceFile(attachmentFile);
      }

      if (resource.tags) {
        setResourceTags(resource.tags)
      }

      const newSections = [];

      if (resource.sections) {
        resource.sections.forEach((section, index) => newSections.push(createSection(section)));
      }
      setSections(newSections);
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    let error = false;
    let errorSections = false;
    const handleSections = sections;

    if (!(title && title.trim())) {
      setErrorTitle("This field can't be blank");
      setTitle('');
      error = true;
    }

    if (resourceType == 'link' && !(resourceLink && resourceLink.trim())) {
      setErrorResourceLink("This field can't be blank");
      setResourceLink('');
      error = true;
    }

    if (resourceType == 'post' && !(resourceBody && resourceBody.trim())) {
      setErrorResourceBody("This field can't be blank");
      setResourceBody('');
      error = true;
    }

    if (resourceType == 'upload' && !(resourceFile)) {
      setErrorResourceFile("This field can't be blank");
      setResourceFile(null);
      error = true;
    }

    // handle errors in sections
    sections.forEach((section, index) => {
      if (!(section.title && section.title.trim()) && !section._destroy) {
        handleSections[index].errorTitle = "This field can't be blank";
        error = true;
        errorSections = true;
      }

      if (!(section.content && section.content.trim()) && !section._destroy) {
        handleSections[index].errorContent = "This field can't be blank";
        error = true;
        errorSections = true;
      }
    });

    if (errorSections) {
      scrollOnError();
      setSections([...handleSections]);
    }

    console.log("ERROR", error);
    if (error) {
      scrollOnError();
      setLoading(false);
    } else {
      const sections_attributes = sections;

      sections_attributes.forEach((section) => {
        delete section['errorContent'];
        delete section['errorTitle'];
      });

      const body = {
        title: title.trim(),
        body: resourceBody,
        resource_category_id: resourceCategoryId,
        length_estimate: lengthEstimate,
        resource_type: resourceType,
        url: resourceLink
      };

      let formData = new FormData();

      Object.keys(body).forEach((key) => formData.append(`resource[${key}]`, body[key]))

      // handle cover image
      if (resource) {
        if (coverImage) {
          if (tileImage && tileImage === coverImage) {
            formData.append("resource[cover_image]", JSON.stringify(tileImage));
          } else {
            formData.append("resource[cover_image]", coverImage[0]);
          }
        } else if (tileImage && !coverImage) {
          formData.append("resource[remove_cover_image]", '1');
        }
      } else if (coverImage) {
        formData.append("resource[cover_image]", coverImage[0]);
      }

      // handle resource pdf file
      if (resource) {
        if (resourceFile) {
          if (attachmentFile && attachmentFile === resourceFile) {
            formData.append("resource[file]", JSON.stringify(attachmentFile));
          } else {
            formData.append("resource[file]", resourceFile[0]);
          }
        } else if (attachmentFile && !resourceFile) {
          formData.append("resource[remove_file]", '1');
        }
      } else if (resourceFile) {
        formData.append("resource[file]", resourceFile[0]);
      }

      // Handle secton attributes data
      sections_attributes.forEach((section) => {
        formData.append('resource[sections_attributes][][title]', section.title)
        formData.append('resource[sections_attributes][][content]', section.content)
        if (section.id) {
          formData.append('resource[sections_attributes][][id]', section.id)
        }
        if (section._destroy) {
          formData.append('resource[sections_attributes][][_destroy]', "true")
        }
      });

      if (resourceTags) {
        resourceTags.forEach(tag => {
          formData.append('resource[tag_list][]', tag['name'])
        });
      }

      if (resource && resource.id) {
        api.updateResource(resourceCategoryId, resource.id, formData)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      } else {
        api.createResource(resourceCategoryId, formData)
          .then((res) => window.location.href = res.url)
          .catch((error) => console.error("Error: ", error));
      }
    }
  }

  const scrollOnError = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const setValues = (
    index,
    content,
    error,
    value,
  ) => {
    const newSections = sections;

    if (typeof content === 'object') {
      newSections[index][content[0]][content[1]][content[2]] = value;
      newSections[index][error[0]][error[1]][error[2]] = '';
    } else {
      newSections[index][content] = value;
      newSections[index][error] = '';
    }

    setSections([...newSections]);
  }

  const handleDeleteSection = (index) => {
    const newSections = sections;

    if (resource) {
      newSections[index]._destroy = true;
    } else {
      newSections.splice(index, 1);
    }

    setSections([...newSections]);
  }

  return (
    <div className="lessons_new watch_form_changes">
      <div className="lessons_new__container">
        <Header
          title={I18n.t(resource.id ? 'models.resource.messaging.edit' : 'models.resource.messaging.create')}
        />
        <BreadCrumb
          locations={[
            { title: I18n.t('models.resource_category.attributes.categories'), url: '/admin/explore_categories/' },
            { title: resourceCategoryTitle, url: `/admin/explore_categories/${resourceCategoryId}/resources/` },
            { title: I18n.t(resource.id ? 'models.resource.messaging.edit' : 'models.resource.messaging.create'), url: '' }
          ]}
        />

        <div className="col-md-12" style={{ marginTop: '60px' }}>
          <div className="row">
            <TextInput
              columnSize={6}
              error={errorTitle}
              value={title}
              type="resource"
              field="title"
              label={I18n.t('models.resource.attributes.title')}
              style={{ paddingLeft: 0 }}
              className="required"
              onChange={(value) => {
                setTitle(value);
                setErrorTitle('');
              }}
            />
            <TextInput
              columnSize={6}
              error={null}
              value={lengthEstimate}
              type="resource"
              field="length_estimate"
              label={I18n.t('models.resource.attributes.duration_label')}
              style={{ paddingLeft: 0 }}
              onChange={(value) => {
                setLengthEstimate(value);
              }}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <SelectInput
              columnSize={6}
              value={resourceType}
              type="resource"
              field="resource_type"
              label={I18n.t('models.common.type')}
              style={{ paddingLeft: 0 }}
              className="required"
              onChange={(value) => setResourceType(value)}
            >
              <option key={0} value='upload'>{I18n.t('models.resource.types.pdf')}</option>
              <option key={1} value='link'>{I18n.t('models.resource.types.link')}</option>
              <option key={2} value='post'>{I18n.t('models.resource.types.post')}</option>
            </SelectInput>

              {/* PDF File Upload */}
              { (resourceType == 'upload' || resourceType == 'pdf' || resourceType == 'post') && (
                <div className="col-md-6" style={{ paddingLeft:0 }}>
                    <SingleFileUpload
                      id="resource_file_field"
                      parent="resource"
                      field="file"
                      label={resourceType == 'post' ? I18n.t('models.resource.attributes.post_file') : I18n.t('models.resource.attributes.file')}
                      existingFileUrl={resource.resource_url}
                      existingFileName={resource.file_name}
                      accepts=".pdf"
                      required={resourceType == 'upload'}
                      errors={errorResourceFile ? [errorResourceFile] : null}
                      onChange={(value) => {
                        setResourceFile(value);
                        setErrorResourceFile('');
                      }}
                      onDelete={() => {
                        setResourceFile(null);
                      }}
                    />
                  </div>
                )}

              {/* Link Input */}
              { (resourceType == 'link') && (
                <TextInput
                  columnSize={6}
                  error={errorResourceLink}
                  value={resourceLink}
                  type="resource"
                  field="url"
                  label={I18n.t('models.resource.attributes.url')}
                  style={{ paddingLeft: 0 }}
                  onChange={(value) => {
                    setResourceLink(value);
                    setErrorResourceLink('');
                  }}
                  className="required"
                />
              )}

          </div>
        </div>

        <div className="col-md-12" style={{ paddingLeft:0 }}>
          <SingleFileUpload
            id="resource_cover_image_field"
            parent="resource"
            field="cover_image"
            label={I18n.t('models.resource.attributes.cover_image')}
            existingFileUrl={resource ? resource.cover_image_url : null}
            existingFileName={resource ? resource.cover_image_name : null}
            accepts=".jpeg,.jpg,.png"
            onChange={(value) => {
              setCoverImage(value)
            }}
            onDelete={() => {
              setCoverImage(null);
            }}
            required={false}
          />
        </div>


        <div className="col-md-12" style={{ paddingLeft: '0' }}>
          <label htmlFor="tags">{I18n.t('models.tag.tags')}</label>
          <TagListInput
            taggable_type={'Resource'}
            tags={resource.tags ? resource.tags : null}
            onChange={(value) => setResourceTags(value)}
          />
        </div>

        { resourceType == 'post' && (
            <NoteInput
            error={errorResourceBody}
            value={resource ? resource.body : null}
            type="resource"
            field="body"
            label={I18n.t('models.resource.attributes.body')}
            style={{ padding: 0, marginTop: '30px' }}
            required
            onChange={(text) => {
              setResourceBody(text);
              setErrorResourceBody('');
            }}
            toolbarOptions={[
              ['style', ['style']],
              ['font', ['bold', 'italic', 'underline', 'clear']],
              ['para', ['ul', 'ol', 'paragraph']],
              ['table', ['table']],
              ['insert', ['link', 'picture', 'video']],
              ['view', ['codeview']],
            ]}
          />
        )}


      { resourceType == 'post' &&
        sections.map((section, index) => (
          <div
            style={{ width: '100%' }}
            key={index}
          >
            {
              !(section._destroy) && (
                <div className="lessons_new__container--big">
                  <div className="lessons_new__content-section">
                    <div className="lessons_new__section app-text">{I18n.t('views.lessons.index.section.header')}</div>
                    <div
                      id={`delete_${index}`}
                      onClick={() => handleDeleteSection(index)}
                    >
                      <i className="fal fa-trash fa-lg lessons_new__icon" />
                    </div>
                  </div>

                  <div className="section">
                    <div className="row" style={{ marginTop: '25px' }}>
                      <TextInput
                        columnSize={12}
                        error={sections[index].errorTitle}
                        value={sections[index].title}
                        type="resource"
                        field={`${index}_title`}
                        label={I18n.t('views.lessons.index.section.title')}
                        className="required"
                        onChange={(value) => setValues(index, 'title', 'errorTitle', value)}
                      />
                    </div>
                  </div>

                  <NoteInput
                    error={sections[index].errorContent}
                    value={sections[index].content}
                    type="resource"
                    field={`${index}_content`}
                    style={{ padding: 0, marginTop: '30px' }}
                    label={I18n.t('views.lessons.index.section.content')}
                    required
                    onChange={(value) => setValues(index, 'content', 'errorContent', value)}
                    toolbarOptions={[
                      ['style', ['style']],
                      ['font', ['bold', 'italic', 'underline', 'clear']],
                      ['para', ['ul', 'ol', 'paragraph']],
                      ['table', ['table']],
                      ['insert', ['link', 'picture', 'video']],
                      ['view', ['codeview']],
                    ]}
                  />
                </div>
              )
            }
          </div>
        ))
      }

      </div>

      { resourceType == 'post' && (
        <div
          className="lessons_new__button-container"
          onClick={() => {
            const newSections = sections;

            newSections.push(createSection());
            setSections([...newSections]);
          }}
          style={{ marginBottom: '40px', fontSize: '24px' }}
        >
          <i className="far fa-plus-circle mr-2" />
          <div className="lessons_new__button-text app-text">{I18n.t('views.lessons.index.section.add_section')}</div>
        </div>
      )}

      <div
        className="col-12 text-center"
        style={{ paddingTop: '40px' }}
      >
        <button
          type="submit"
          name="commit"
          disabled={loading}
          className="btn btn-primary pull-right"
          onClick={handleSubmit}
        >
          {I18n.t('views.forms.submit')}
          { loading && <i className='far fa-spinner-third fa-spin btn-loader ml-2'></i> }
        </button>
      </div>
    </div>
  );
}

export default CreateResource;
